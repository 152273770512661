import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { debounce } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';

import {
  FilterConditionOperatorEnum,
  FilterInput,
  GetInterviewsQuery,
  useGetInterviewsQuery,
  useGetUsersQuery,
} from '@/__generated__/graphql';
import BaseTable from '@/components/BaseTable/BaseTable';
import { FilterPanel } from '@/components/FilterPanel/FilterPanel';
import { IFilter } from '@/components/FilterPanel/FilterPanelContent';
import Button from '@/components/basic/buttons/Button';
import Input from '@/components/basic/inputs/Input';
import { BasicLayout } from '@/components/layouts/BasicLayout/BasicLayout';
import { useAuth } from '@/hooks/useAuth';
import { UpsertInterviewModal } from '@/pages/Interviews/InterviewsPage/UpsertInterviewModal';
import { RolesEnum } from '@/shared/enums';
import { hasBaseRoleAccess, hasRoleAccess, isRoleAdmin } from '@/shared/utils/rolesUtils';
import { formatPhoneNumber, removeInvalidFilters } from '@/shared/utils/utils';

export const InterviewsPage = () => {
  const { user } = useAuth();
  const [pagination, setPagination] = useState({ pageSize: 30, pageIndex: 0 });
  const [sorting, setSorting] = useState<SortingState>([{ id: 'date', desc: true }]);
  const usersRes = useGetUsersQuery();
  const users = usersRes.data?.getUsers;
  const isAdmin =
    isRoleAdmin(user!.roles, 'interviewer') ||
    hasRoleAccess(user!.roles, [RolesEnum.interviewerApprover]);
  const [upsertModalOpen, setUpsertModalOpen] = useState(false);
  const [selectedInterview, setSelectedInterview] =
    useState<GetInterviewsQuery['getInterviews']['interviews'][0]>();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearch = useRef(
    debounce(async (criteria: string) => {
      setSearchTerm(criteria);
    }, 300),
  ).current;
  const [filters, setFilters] = useState<FilterInput[]>([]);
  const {
    data: getInterviewRes,
    loading,
    refetch,
  } = useGetInterviewsQuery({
    variables: {
      filters: removeInvalidFilters(filters),
      pagination: {
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      },
      sort: sorting.length
        ? {
            id: sorting[0]?.id,
            order: sorting[0]?.desc ? 'DESC' : 'ASC',
          }
        : undefined,
    },
  });
  const interviews = getInterviewRes?.getInterviews?.interviews;
  const totalRecords = getInterviewRes?.getInterviews.totalRecords;

  const columns: ColumnDef<GetInterviewsQuery['getInterviews']['interviews'][0]>[] = [
    { accessorKey: 'id', header: 'ID' },
    { accessorKey: 'status', header: 'Status' },
    {
      accessorKey: 'date',
      header: 'Interview Date',
      accessorFn: (record) =>
        record.date ? dayjs(record.date).format('MMM D, YYYY h:mm A z') : '',
      sortingFn: 'datetime',
    },
    {
      accessorKey: 'person',
      header: 'Person',
      accessorFn: (record) => `${record.person?.first_name} ${record.person?.last_name}`,
      enableSorting: false,
    },
    {
      accessorKey: 'personStatus',
      header: 'Person Status',
      accessorFn: (record) => record.person.status,
      enableSorting: false,
    },
    {
      accessorKey: 'phoneNumber',
      header: 'Phone Number',
      accessorFn: (record) => formatPhoneNumber(record.person.phone_number || ''),
      enableSorting: false,
    },
    {
      accessorKey: 'personEmail',
      header: 'Email',
      accessorFn: (record) => record.person?.primary_email,
      enableSorting: false,
    },
    {
      accessorKey: 'interviewer',
      header: 'Interviewer',
      accessorFn: (record) => record.interviewer?.name,
      enableSorting: false,
    },
    {
      accessorKey: 'city',
      header: 'City',
      accessorFn: (record) => record.person.city,
      enableSorting: false,
    },
    {
      accessorKey: 'membership_charge_period',
      header: 'Membership',
      accessorFn: (record) => record.person.membership_charge_period,
      enableSorting: false,
    },
    {
      accessorKey: 'created_at',
      header: 'Created At',
      accessorFn: (record) => dayjs(record.created_at).format('MMM D, YYYY'),
      enableSorting: true,
    },
    {
      header: 'Details',
      accessorFn: (record) => {
        return record.id;
      },
      enableSorting: false,
      cell: (record) => {
        return (
          <a
            onClick={(e) => e.stopPropagation()}
            href={`/interview-queue?i=${record.getValue()}`}
            target={'_blank'}
            rel="noreferrer"
          >
            <ArrowUpRightIcon className={'w-4'} />
          </a>
        );
      },
    },
  ];

  const getFilters = (): IFilter[] => {
    const filterOperations: IFilter[] = [
      { field: 'status', type: 'string', operations: [FilterConditionOperatorEnum.Contains] },
      { field: 'city', type: 'string', operations: [FilterConditionOperatorEnum.Contains] },
      {
        field: 'date',
        type: 'date',
        operations: [
          FilterConditionOperatorEnum.GreaterThan,
          FilterConditionOperatorEnum.GreaterThanEquals,
          FilterConditionOperatorEnum.LessThan,
          FilterConditionOperatorEnum.LessThanEquals,
          FilterConditionOperatorEnum.Equals,
        ],
      },
      {
        field: 'person_status',
        type: 'string',
        operations: [FilterConditionOperatorEnum.Contains],
      },
    ];

    filterOperations.push({
      field: 'interviewer',
      type: 'string',
      values: users
        ?.filter((user) => hasBaseRoleAccess(user.roles, 'interviewer'))
        .map((u) => u.name),
      operations: [FilterConditionOperatorEnum.Equals],
    });

    return filterOperations;
  };

  const refetchInterviews = async () => {
    const tempFilters = removeInvalidFilters([
      ...filters,
      { field: 'search', operator: FilterConditionOperatorEnum.Contains, value: searchTerm },
    ]);

    // if (!isAdmin) {
    //   tempFilters.push({
    //     field: 'interviewer',
    //     operator: FilterConditionOperatorEnum.Equals,
    //     value: user!.name,
    //   });
    // }

    refetch({
      sort: sorting.length
        ? {
            id: sorting[0]?.id,
            order: sorting[0]?.desc ? 'DESC' : 'ASC',
          }
        : undefined,
      pagination: {
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      },
      filters: tempFilters,
    });
  };

  const handleClose = () => {
    setUpsertModalOpen(false);
    setSelectedInterview(undefined);
    refetchInterviews();
  };

  useEffect(() => {
    refetchInterviews();
  }, [filters, sorting, pagination, searchTerm]);

  useEffect(() => {
    if (selectedInterview) {
      setUpsertModalOpen(true);
    }
  }, [selectedInterview]);

  return (
    <BasicLayout title={'All Interviews'} className={'flex flex-col'}>
      <div className={'flex h-10 w-full items-center justify-end border-y border-neutral px-4'}>
        <Input
          onChange={(event) => debouncedSearch(event.target.value)}
          className={'w-[300px] rounded-none'}
          placeholder={'Search name, email, interviewer'}
          inputSize={'sm'}
          fullWidth={false}
        />
        <span className={'flex-1'} />
        <Button color={'success'} size={'xs'} onClick={() => setUpsertModalOpen(true)}>
          New Interview
        </Button>
        <FilterPanel filterKeyValues={getFilters()} filters={filters} setFilters={setFilters} />
      </div>

      <BaseTable
        paginationDetails={pagination}
        sorting={sorting}
        data={interviews || []}
        columns={columns}
        loading={loading}
        paginationEnabled={true}
        manualPagination={true}
        onRowClick={setSelectedInterview}
        onSortingChange={setSorting}
        onPaginationChange={setPagination}
        totalRecords={totalRecords}
      />

      <UpsertInterviewModal
        interview={selectedInterview}
        isOpen={upsertModalOpen}
        onClose={handleClose}
        interviewerId={isAdmin ? undefined : user!.id}
      />
    </BasicLayout>
  );
};
