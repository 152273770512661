export type SimpleTableColumn<T> = {
  id?: string;
  headerName: string;
  accessorKey: (string & {}) | keyof T;
  accessorFunc?: (record: T) => any;
  hide?: boolean;
  className?: string;
  enableTooltip?: boolean;
};

interface Props<T> {
  data: T[];
  columns: SimpleTableColumn<T>[];
  loading?: boolean;
}

export const SimpleTable = <T,>({ data, columns, loading }: Props<T>) => {
  return (
    <div className="relative max-h-[300px] overflow-y-auto">
      <table className="table w-full min-w-[300px]">
        <thead className={'sticky top-0 bg-white'}>
          <tr>
            {columns.map((column, index) => {
              if (column.hide) return null;
              return <th key={column.id || column.headerName || index}>{column.headerName}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((record, index) => {
            return (
              <tr key={index}>
                {columns.map((column) => {
                  if (column.hide) {
                    return null;
                  }
                  return (
                    <td
                      title={
                        column.enableTooltip &&
                        (column.accessorFunc
                          ? column.accessorFunc(record)
                          : record?.[column.accessorKey as string])
                      }
                      className={column?.className}
                      key={`${index}-${column.id || column.headerName || (column?.accessorKey as string)}`}
                    >
                      {column.accessorFunc
                        ? column.accessorFunc(record)
                        : record?.[column.accessorKey as string]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          {!data?.length && (
            <tr>
              <td className={'min w-full min-w-[300px] text-center text-slate-300'}>No Records</td>
            </tr>
          )}
        </tbody>
      </table>
      {loading && (
        <div className={'loading loading-lg absolute left-1/2 top-1/2 -translate-x-1/2'} />
      )}
    </div>
  );
};
