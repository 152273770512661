import { Person } from '@/__generated__/graphql';
import { DetailTableSection } from '@/components/sections/DetailTableSection';

type Props = {
  person: Person;
};

export const ApplicationDetails = ({ person }: Props) => {
  const details = [
    {
      title: 'Membership Type',
      value: person?.membership_charge_period,
    },
    {
      title: 'Archtype',
      value: person?.archetype,
    },
    {
      title: 'Work',
      value: person?.work_description,
    },
    {
      title: 'Industry',
      value: person?.industry,
    },
    {
      title: 'Area of Study',
      value: person?.area_of_study,
    },
    {
      title: 'Dinner Persona',
      value: person?.dinner_persona,
    },
    {
      title: 'Accomplishments',
      value: person?.accomplishments,
    },
    {
      title: '3 Words',
      value: person?.three_words,
    },
    {
      title: 'Interests',
      value: person?.interests,
    },
    {
      title: 'Dinner Guest',
      value: person?.application_dinner_guest,
    },
    {
      title: 'Nomination code',
      value: person?.nomination_code,
    },
  ];

  return <DetailTableSection title={'Application'} details={details} />;
};
