import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';

import { GetPersonQuery, useGetPersonLazyQuery } from '@/__generated__/graphql';
import { RightSideDrawerBase } from '@/components/RightSideDrawerBase';
import { PersonPageContent } from '@/components/personDetailSections/PersonPageContent';

type Props = {
  personId?: string;
  onClose: (refetch?: boolean) => void;
};

export const PersonDetailsSideDrawer = ({ personId, onClose }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [person, setPerson] = useState<GetPersonQuery['getPerson']>();
  const [error, setError] = useState<string | undefined>();
  const [getPerson] = useGetPersonLazyQuery();

  useEffect(() => {
    if (personId) {
      setIsOpen(true);
      const fetchPerson = async () => {
        const { data, error } = await getPerson({ variables: { id: personId } });
        setPerson(data?.getPerson || undefined);
        if (error?.message) {
          captureException(error);
          setError(error.message);
        }
      };

      fetchPerson();
    } else {
      setIsOpen(false);
      setPerson(undefined);
    }
  }, [personId]);

  return (
    <RightSideDrawerBase
      isOpen={isOpen}
      onClose={() => onClose(true)}
      className={'p-0 pt-1 md:w-2/3 2xl:w-2/3'}
    >
      {error && <p className={'text-error'}>{error}</p>}

      {person?.id ? <PersonPageContent id={person?.id} /> : 'No Person'}
    </RightSideDrawerBase>
  );
};
