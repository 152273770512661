import { FilterConditionOperatorEnum } from '@/__generated__/graphql';

export const applyFilter = (
  itemValue: string,
  operator: FilterConditionOperatorEnum,
  conditionValue: string,
): boolean => {
  // Attempt to parse numeric values
  const itemNum = parseFloat(itemValue);
  const condNum = parseFloat(conditionValue);

  // Determine if the values can be treated as numbers
  const areNumbers = !isNaN(itemNum) && !isNaN(condNum);

  switch (operator) {
    case FilterConditionOperatorEnum.Equals:
      return areNumbers
        ? itemNum === condNum
        : itemValue.toLowerCase() === conditionValue.toLowerCase();
    case FilterConditionOperatorEnum.NotEquals:
      return areNumbers
        ? itemNum !== condNum
        : itemValue.toLowerCase() !== conditionValue.toLowerCase();
    case FilterConditionOperatorEnum.Contains:
      return itemValue.toLowerCase().includes(conditionValue.toLowerCase());
    case FilterConditionOperatorEnum.LessThan:
      return areNumbers ? itemNum < condNum : false;
    case FilterConditionOperatorEnum.LessThanEquals:
      return areNumbers ? itemNum <= condNum : false;
    case FilterConditionOperatorEnum.GreaterThan:
      return areNumbers ? itemNum > condNum : false;
    case FilterConditionOperatorEnum.GreaterThanEquals:
      return areNumbers ? itemNum >= condNum : false;
    default:
      return false;
  }
};
