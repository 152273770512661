import { PencilIcon } from '@heroicons/react/24/outline';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { debounce } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';

import {
  FilterConditionOperatorEnum,
  FilterInput,
  GetManagedAccountQuery,
  useGetManagedAccountQuery,
} from '@/__generated__/graphql';
import BaseTable from '@/components/BaseTable/BaseTable';
import { FilterPanel } from '@/components/FilterPanel/FilterPanel';
import { IFilter } from '@/components/FilterPanel/FilterPanelContent';
import Button from '@/components/basic/buttons/Button';
import Input from '@/components/basic/inputs/Input';
import { BasicLayout } from '@/components/layouts/BasicLayout/BasicLayout';
import { PersonDetailsSideDrawer } from '@/components/personDetailSections/PersonDetailsSideDrawer';
import { AccountManagementQuickFilters } from '@/pages/AccountManagerPage/components/AccountManagementQuickFilters';
import { ChangeAccountManagerModal } from '@/pages/AccountManagerPage/components/ChangeAccountManagerModal';
import { formatPhoneNumber, removeInvalidFilters } from '@/shared/utils/utils';

export const AccountManagerPage = () => {
  const [pagination, setPagination] = useState({ pageSize: 30, pageIndex: 0 });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filters, setFilters] = useState<FilterInput[]>([]);
  const [accountDetails, setAccountDetails] =
    useState<GetManagedAccountQuery['getManagedAccounts']['accounts'][0]>();
  const [changeAccountManagerData, setChangeAccountManagerData] = useState({
    personId: '',
    managerId: '',
  });

  const {
    data: managedAccountsRes,
    loading,
    refetch,
  } = useGetManagedAccountQuery({
    variables: {
      sort: sorting.length
        ? {
            id: sorting[0]?.id,
            order: sorting[0]?.desc ? 'DESC' : 'ASC',
          }
        : undefined,
      pagination: {
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      },
      filters: removeInvalidFilters(filters),
    },
  });
  const managedAccounts = managedAccountsRes?.getManagedAccounts?.accounts || [];
  const totalRecords = managedAccountsRes?.getManagedAccounts.totalRecords;
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearch = useRef(
    debounce(async (criteria: string) => {
      setSearchTerm(criteria);
    }, 300),
  ).current;

  const columns: ColumnDef<GetManagedAccountQuery['getManagedAccounts']['accounts'][0]>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
      enableSorting: false,
    },
    {
      accessorFn: (row) => `${row.person.first_name} ${row.person.last_name}`,
      id: 'person_name',
      header: 'Person Name',
      enableSorting: false,
    },
    {
      accessorFn: (row) => row.person.primary_email,
      id: 'primary_email',
      header: 'Email',
    },
    {
      accessorFn: (row) => row.person.status,
      id: 'status',
      header: 'Status',
    },
    {
      accessorFn: (row) => row.person.phone_number,
      id: 'phone_number',
      enableSorting: false,
      header: 'Phone Number',
      cell: (info) => formatPhoneNumber(info.getValue() as string),
    },
    {
      accessorFn: (row) => row.person.membership_started_at,
      id: 'membership_started_at',
      enableSorting: false,
      header: 'Joined Date',
      cell: (info) => {
        return info.getValue() ? dayjs(info.getValue() as string).format('MM/DD/YY') : '-';
      },
    },
    {
      accessorFn: (row) => row.person.city,
      id: 'city',
      enableSorting: true,
      header: 'City',
    },
    {
      accessorFn: (row) => row.account_manager_name || '-',
      id: 'account_manager',
      header: 'Account Manager',
      enableSorting: false,
      cell: (info) => (
        <div>
          {info.getValue() as string}{' '}
          <Button
            onClick={(e) =>
              onChangeAccountManagerClick(
                e,
                info?.row?.original?.person?.id as string,
                info?.row?.original?.account_manager_id as string,
              )
            }
            color={'ghost'}
            size={'xs'}
          >
            <PencilIcon className={'h-3 w-3'} />
          </Button>
        </div>
      ),
    },
    {
      accessorKey: 'dinners_count',
      header: 'Dinners Count',
    },
    {
      accessorKey: 'experiences_count',
      header: 'Experiences Count',
    },
    {
      accessorFn: (record) => record.person.membership_charge_period,
      header: 'Charge Period',
    },
  ];

  const getFilters = (): IFilter[] => {
    return [
      { field: 'total_events', type: 'number' },
      { field: 'city', type: 'string' },
      { field: 'dinners_count', type: 'number' },
      { field: 'experiences_count', type: 'number' },
      {
        field: 'membership_started_at',
        type: 'date',
        operations: [FilterConditionOperatorEnum.LessThan, FilterConditionOperatorEnum.GreaterThan],
      },
    ];
  };

  function onChangeAccountManagerClick(e, personId: string, managerId?: string) {
    e.stopPropagation();

    setChangeAccountManagerData({
      personId,
      managerId: managerId || '',
    });
  }

  const handleChangeAccountManagerModalClose = async () => {
    refetchAccounts();
    setChangeAccountManagerData({
      personId: '',
      managerId: '',
    });
  };

  const handleAccountDetailsClose = async () => {
    setAccountDetails(undefined);
    await refetchAccounts();
  };

  const refetchAccounts = async () => {
    await refetch({
      sort: sorting.length
        ? {
            id: sorting[0]?.id,
            order: sorting[0]?.desc ? 'DESC' : 'ASC',
          }
        : undefined,
      pagination: {
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      },
      filters: removeInvalidFilters([
        ...filters,
        {
          field: 'search',
          operator: FilterConditionOperatorEnum.Contains,
          value: searchTerm,
        },
      ]),
    });
  };

  useEffect(() => {
    refetchAccounts();
  }, [filters, sorting, pagination, searchTerm]);

  return (
    <BasicLayout title={'Account Manager'} className={'flex flex-col'}>
      <div
        className={
          'flex h-10 min-h-max w-full flex-wrap items-center justify-end border-y border-neutral px-4'
        }
      >
        <Input
          onChange={(event) => debouncedSearch(event.target.value)}
          className={'w-[300px] rounded-none'}
          placeholder={'Search person name or email'}
          inputSize={'sm'}
          fullWidth={false}
        />
        <span className={'flex-1'} />
        <AccountManagementQuickFilters setFilters={setFilters} filters={filters} />
        <FilterPanel filterKeyValues={getFilters()} filters={filters} setFilters={setFilters} />
      </div>

      <BaseTable
        data={managedAccounts}
        onRowClick={setAccountDetails}
        columns={columns}
        loading={loading}
        paginationEnabled={true}
        manualPagination={true}
        paginationDetails={pagination}
        totalRecords={totalRecords}
        onPaginationChange={setPagination}
        sorting={sorting}
        onSortingChange={setSorting}
      />
      <PersonDetailsSideDrawer
        personId={accountDetails?.person?.id}
        onClose={handleAccountDetailsClose}
      />

      <ChangeAccountManagerModal
        currentManagerId={changeAccountManagerData?.managerId}
        personId={changeAccountManagerData.personId}
        isOpen={!!changeAccountManagerData?.personId}
        onClose={handleChangeAccountManagerModalClose}
      />
    </BasicLayout>
  );
};
