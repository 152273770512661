import { useEffect, useState } from 'react';

import { GetStatsQuery } from '@/__generated__/graphql';
import Select from '@/components/basic/inputs/Select';
import { Stat } from '@/components/stats/Stat';

type Props = {
  stats: GetStatsQuery['getInterviewStats'];
  isAdmin?: boolean;
  userId: string;
};

type MembershipObject = {
  quarterly: number;
  yearly: number;
  monthly: number;
};

export const InterviewStatsMemberships = ({ isAdmin, stats, userId }: Props) => {
  const [teamStats, setTeamStats] = useState<MembershipObject>({
    quarterly: 0,
    yearly: 0,
    monthly: 0,
  });
  const [selectedUserId, setSelectedUserId] = useState(isAdmin ? '' : userId);
  const selectedStats = stats.find((s) => s.interviewerId === selectedUserId);

  useEffect(() => {
    const tempStats = {
      quarterly: 0,
      yearly: 0,
      monthly: 0,
    };

    stats.forEach((s) => {
      tempStats.yearly += s.subscriptionTypes?.yearly || 0;
      tempStats.quarterly += s.subscriptionTypes?.quarterly || 0;
      tempStats.monthly += s.subscriptionTypes?.monthly || 0;
    });

    setTeamStats(tempStats);
  }, [stats]);

  return (
    <div className={'flex h-full flex-col gap-4 p-4'}>
      <div>
        <h3 className={'text-lg font-bold'}>Team Memberships</h3>
        <div className={'flex gap-5'}>
          <Stat title={'Yearly'} value={teamStats.yearly} />
          <Stat title={'Quarterly'} value={teamStats.quarterly} />
          <Stat title={'Monthly'} value={teamStats.monthly} />
        </div>
      </div>
      <div>
        <div className={'mb-4 flex items-center justify-between'}>
          <h3 className={'text-lg font-bold'}>{isAdmin ? 'Selected' : 'My'} Memberships</h3>
          {isAdmin && (
            <Select
              inputSize={'xs'}
              fullWidth={false}
              onChange={(e) => setSelectedUserId(e.target.value)}
            >
              <option value={''}>Select Interviewer</option>
              {stats.map((s) => (
                <option key={s.interviewerId} value={s.interviewerId}>
                  {s.interviewerName}
                </option>
              ))}
            </Select>
          )}
        </div>
        <div className={'flex gap-5'}>
          <Stat
            title={'Yearly'}
            value={selectedUserId ? selectedStats?.subscriptionTypes?.yearly || 0 : '-'}
          />
          <Stat
            title={'Quarterly'}
            value={selectedUserId ? selectedStats?.subscriptionTypes?.quarterly || 0 : '-'}
          />
          <Stat
            title={'Monthly'}
            value={selectedUserId ? selectedStats?.subscriptionTypes?.monthly || 0 : '-'}
          />
        </div>
      </div>
    </div>
  );
};
