import { ApolloProvider } from '@apollo/client';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { apolloClient } from '@/modules/apolloClient';
import '@/modules/dayjsPlugins';
import '@/modules/logrocketConfiguration';
import '@/modules/sentryConfig';
import '@/styles/index.scss';

import App from './App';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
        <SnackbarProvider
          autoHideDuration={3000}
          action={(snackbarId) => (
            <button onClick={() => closeSnackbar(snackbarId)}>
              <XMarkIcon />
            </button>
          )}
        >
          <App />
        </SnackbarProvider>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
);
