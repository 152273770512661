import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  DateTimeISO: { input: any; output: any };
  JSON: { input: any; output: any };
};

export type AccountManagement = {
  account_manager_id?: Maybe<Scalars['String']['output']>;
  account_manager_name?: Maybe<Scalars['String']['output']>;
  dinners_count?: Maybe<Scalars['Float']['output']>;
  experiences_count?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  person: Person;
  person_id?: Maybe<Scalars['String']['output']>;
};

export type AccountManagementResponse = {
  accounts: Array<AccountManagement>;
  totalRecords: Scalars['Float']['output'];
};

export type AssociationInput = {
  entity_id: Scalars['String']['input'];
  entity_type: KanbanAssociationTypeEnum;
};

export type Availability = {
  city: Scalars['String']['output'];
  created_at: Scalars['Date']['output'];
  date: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  person_id: Scalars['String']['output'];
  updated_at: Scalars['Date']['output'];
};

export type CreateInterview = {
  date: Scalars['DateTimeISO']['input'];
  interviewer_id: Scalars['String']['input'];
  person_id: Scalars['String']['input'];
  status: Scalars['String']['input'];
};

export type CreateKanbanCardInput = {
  associations: Array<AssociationInput>;
  board_type: KanbanBoardTypeEnum;
  status: Scalars['String']['input'];
};

export type CreateUserLogInput = {
  content: Scalars['String']['input'];
  person_id: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
  user_id: Scalars['String']['input'];
};

export type Dinner = {
  airtable_id: Scalars['String']['output'];
  available_seats?: Maybe<Scalars['Float']['output']>;
  booking_url?: Maybe<Scalars['String']['output']>;
  codename: Scalars['String']['output'];
  cost_per_seat?: Maybe<Scalars['Float']['output']>;
  created_at: Scalars['DateTimeISO']['output'];
  date: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  location?: Maybe<Location>;
  location_id?: Maybe<Scalars['String']['output']>;
  max_seats?: Maybe<Scalars['Float']['output']>;
  price_per_seat?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTimeISO']['output'];
};

export type DinnerMatch = {
  created_at: Scalars['DateTimeISO']['output'];
  deadline_at?: Maybe<Scalars['DateTimeISO']['output']>;
  dinner?: Maybe<Dinner>;
  id: Scalars['String']['output'];
  location?: Maybe<Location>;
  matched_dinner_id: Scalars['String']['output'];
  person_id: Scalars['String']['output'];
  show_feedback: Scalars['Boolean']['output'];
  status: Scalars['String']['output'];
  stripe_payment_intent_id?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTimeISO']['output'];
};

export type DinnerView = {
  booking_url?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  codename: Scalars['String']['output'];
  cost_per_seat?: Maybe<Scalars['Float']['output']>;
  created_at: Scalars['String']['output'];
  date: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  location_id?: Maybe<Scalars['String']['output']>;
  max_seats?: Maybe<Scalars['Float']['output']>;
  price_per_seat?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['String']['output'];
};

export type DinnersResponse = {
  count: Scalars['Float']['output'];
  dinners: Array<DinnerView>;
};

export type Event = {
  created_at: Scalars['DateTimeISO']['output'];
  dinner_id?: Maybe<Scalars['String']['output']>;
  experience_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  person_id?: Maybe<Scalars['String']['output']>;
  properties?: Maybe<Scalars['JSON']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Experience = {
  airtable_id?: Maybe<Scalars['String']['output']>;
  available_tickets: Scalars['Float']['output'];
  base_cost?: Maybe<Scalars['Float']['output']>;
  cost_per_seat: Scalars['Float']['output'];
  created_at: Scalars['DateTimeISO']['output'];
  date: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  experience_category?: Maybe<Scalars['String']['output']>;
  experience_type: Scalars['String']['output'];
  feedback: Array<ExperienceFeedback>;
  id: Scalars['String']['output'];
  last_booking_at?: Maybe<Scalars['DateTimeISO']['output']>;
  location?: Maybe<Location>;
  location_id?: Maybe<Scalars['String']['output']>;
  max_guests_per_member: Scalars['Float']['output'];
  max_participants: Scalars['Float']['output'];
  price_per_seat: Scalars['Float']['output'];
  publish_at?: Maybe<Scalars['DateTimeISO']['output']>;
  short_description?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  title: Scalars['String']['output'];
  transactions: Array<ExperienceTransaction>;
  updated_at?: Maybe<Scalars['DateTimeISO']['output']>;
  waitlist_active: Scalars['Boolean']['output'];
};

export type ExperienceFeedback = {
  created_at: Scalars['DateTimeISO']['output'];
  experience_id?: Maybe<Scalars['String']['output']>;
  feedback?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  overall_rating?: Maybe<Scalars['String']['output']>;
  person_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type ExperienceTransaction = {
  created_at: Scalars['DateTimeISO']['output'];
  experience?: Maybe<Experience>;
  experience_id: Scalars['String']['output'];
  feedback?: Maybe<ExperienceFeedback>;
  id: Scalars['String']['output'];
  location?: Maybe<Location>;
  person_id: Scalars['String']['output'];
  ticket_count?: Maybe<Scalars['Float']['output']>;
  transaction: Transaction;
  transaction_id: Scalars['String']['output'];
};

export type ExperienceView = {
  address: Scalars['String']['output'];
  booking_count: Scalars['Float']['output'];
  booking_status: Scalars['String']['output'];
  city: Scalars['String']['output'];
  date: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  experience_category?: Maybe<Scalars['String']['output']>;
  experience_type: Scalars['String']['output'];
  guests_allowed?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  last_booking_at?: Maybe<Scalars['DateTimeISO']['output']>;
  max_guests_per_member: Scalars['Float']['output'];
  max_participants: Scalars['Float']['output'];
  neighborhood?: Maybe<Scalars['String']['output']>;
  parking_directions?: Maybe<Scalars['String']['output']>;
  price_per_seat: Scalars['Float']['output'];
  short_description?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  tickets_left: Scalars['Float']['output'];
  title: Scalars['String']['output'];
  waitlist_active: Scalars['Boolean']['output'];
};

export type ExperiencesResponse = {
  count: Scalars['Float']['output'];
  experiences: Array<ExperienceView>;
};

/** Operators for filter conditions */
export enum FilterConditionOperatorEnum {
  Contains = 'CONTAINS',
  Equals = 'EQUALS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanEquals = 'GREATER_THAN_EQUALS',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanEquals = 'LESS_THAN_EQUALS',
  NotEquals = 'NOT_EQUALS',
}

export type FilterInput = {
  field: Scalars['String']['input'];
  operator: FilterConditionOperatorEnum;
  value: Scalars['String']['input'];
};

export type FollowupKanbanCard = {
  board_type: Scalars['String']['output'];
  created_at: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  interview: Interview;
  status: Scalars['String']['output'];
  updated_at: Scalars['DateTimeISO']['output'];
};

export type FollowupKanbanCardInputs = {
  interviewer_id?: InputMaybe<Scalars['String']['input']>;
};

export type Interview = {
  created_at: Scalars['DateTimeISO']['output'];
  date: Scalars['DateTimeISO']['output'];
  deferred_to?: Maybe<Scalars['DateTimeISO']['output']>;
  follow_up_notes?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  interviewer: User;
  interviewer_id: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  person: Person;
  person_id: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updated_at: Scalars['DateTimeISO']['output'];
};

export type InterviewerStatsInput = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type InterviewsResponse = {
  interviews: Array<Interview>;
  totalRecords: Scalars['Float']['output'];
};

/** The entity type of the kanban card association, the table name. */
export enum KanbanAssociationTypeEnum {
  Interviews = 'interviews',
  Persons = 'persons',
  Users = 'users',
}

/** The board type of the kanban card. */
export enum KanbanBoardTypeEnum {
  FollowUpApplicants = 'follow_up_applicants',
}

export type Location = {
  airtable_id?: Maybe<Scalars['String']['output']>;
  arrival_instructions?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  contact_email?: Maybe<Scalars['String']['output']>;
  contact_name?: Maybe<Scalars['String']['output']>;
  contact_phone_number?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTimeISO']['output'];
  cuisine?: Maybe<Scalars['String']['output']>;
  dining_style?: Maybe<Scalars['String']['output']>;
  dress_code?: Maybe<Scalars['String']['output']>;
  executive_chef?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  image_path?: Maybe<Scalars['String']['output']>;
  max_capacity?: Maybe<Scalars['Float']['output']>;
  menu?: Maybe<Scalars['String']['output']>;
  min_capacity?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  neighborhood?: Maybe<Scalars['String']['output']>;
  parking_details?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  street_address?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type LoggedInUser = {
  auth_id?: Maybe<Scalars['String']['output']>;
  deleted_at?: Maybe<Scalars['DateTimeISO']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  roles: Array<Role>;
};

export type Mutation = {
  createInterview: Interview;
  createKanbanCards: FollowupKanbanCard;
  createUser: User;
  createUserLog: UserLog;
  deleteUser: Scalars['Boolean']['output'];
  patchFollowupKanbanCards: FollowupKanbanCard;
  patchInterview: Interview;
  patchPerson: Person;
  updateMembershipStatus: Scalars['String']['output'];
  updateUser: User;
  upsertManagedAccount: AccountManagement;
};

export type MutationCreateInterviewArgs = {
  payload: CreateInterview;
};

export type MutationCreateKanbanCardsArgs = {
  payload: CreateKanbanCardInput;
};

export type MutationCreateUserArgs = {
  input: UserCreateInput;
};

export type MutationCreateUserLogArgs = {
  input: CreateUserLogInput;
};

export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationPatchFollowupKanbanCardsArgs = {
  id: Scalars['String']['input'];
  payload: PatchFollowupKanbanCardInput;
};

export type MutationPatchInterviewArgs = {
  id: Scalars['String']['input'];
  payload: PatchInterview;
};

export type MutationPatchPersonArgs = {
  id: Scalars['String']['input'];
  payload: PatchPerson;
};

export type MutationUpdateMembershipStatusArgs = {
  id: Scalars['String']['input'];
  payload: Scalars['JSON']['input'];
  status: Scalars['String']['input'];
};

export type MutationUpdateUserArgs = {
  id: Scalars['ID']['input'];
  input: UserUpdateInput;
};

export type MutationUpsertManagedAccountArgs = {
  newManagerId: Scalars['String']['input'];
  personId: Scalars['String']['input'];
};

export type PaginationInput = {
  limit: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
};

export type PatchFollowupKanbanCardInput = {
  status: Scalars['String']['input'];
};

export type PatchInterview = {
  date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deferred_to?: InputMaybe<Scalars['DateTimeISO']['input']>;
  follow_up_notes?: InputMaybe<Scalars['String']['input']>;
  interviewer_id?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type PatchPerson = {
  age_matching_preference?: InputMaybe<Scalars['String']['input']>;
  application_age_range?: InputMaybe<Scalars['String']['input']>;
  application_dinner_guest?: InputMaybe<Scalars['String']['input']>;
  archetype?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  dietary_preferences?: InputMaybe<Scalars['String']['input']>;
  dietary_preferences_note?: InputMaybe<Scalars['String']['input']>;
  dinner_persona?: InputMaybe<Scalars['String']['input']>;
  extraversion?: InputMaybe<Scalars['String']['input']>;
  match_after_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  membership_status?: InputMaybe<Scalars['String']['input']>;
  rating_arts_culture?: InputMaybe<Scalars['String']['input']>;
  rating_business?: InputMaybe<Scalars['String']['input']>;
  rating_health_wellness?: InputMaybe<Scalars['String']['input']>;
  rating_history?: InputMaybe<Scalars['String']['input']>;
  rating_literature?: InputMaybe<Scalars['String']['input']>;
  rating_music_film?: InputMaybe<Scalars['String']['input']>;
  rating_philosophy?: InputMaybe<Scalars['String']['input']>;
  rating_politics_events?: InputMaybe<Scalars['String']['input']>;
  rating_spirituality_religion?: InputMaybe<Scalars['String']['input']>;
  rating_sports_recreation?: InputMaybe<Scalars['String']['input']>;
  rating_tech_science?: InputMaybe<Scalars['String']['input']>;
  risk_tolerance?: InputMaybe<Scalars['String']['input']>;
  score_conversation_focus?: InputMaybe<Scalars['Float']['input']>;
  score_energy?: InputMaybe<Scalars['Float']['input']>;
  score_experience?: InputMaybe<Scalars['Float']['input']>;
  score_extraversion?: InputMaybe<Scalars['Float']['input']>;
  score_intention_focus?: InputMaybe<Scalars['Float']['input']>;
  score_overall?: InputMaybe<Scalars['Float']['input']>;
  score_slope?: InputMaybe<Scalars['Float']['input']>;
  score_transactional?: InputMaybe<Scalars['Float']['input']>;
  score_vulnerability?: InputMaybe<Scalars['Float']['input']>;
  score_work_life?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  stripe_customer_id?: InputMaybe<Scalars['String']['input']>;
};

export type Person = {
  access_requirements?: Maybe<Scalars['String']['output']>;
  accomplishments?: Maybe<Scalars['String']['output']>;
  account_management?: Maybe<AccountManagement>;
  age_matching_preference?: Maybe<Scalars['String']['output']>;
  airtable_id?: Maybe<Scalars['String']['output']>;
  application_age_range?: Maybe<Scalars['String']['output']>;
  application_dinner_guest?: Maybe<Scalars['String']['output']>;
  archetype?: Maybe<Scalars['String']['output']>;
  area_of_study?: Maybe<Scalars['String']['output']>;
  availability?: Maybe<Array<Availability>>;
  avatar_path?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  connection_expectation?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['Date']['output']>;
  credit_balance?: Maybe<Scalars['Float']['output']>;
  date_of_birth?: Maybe<Scalars['Date']['output']>;
  dietary_preferences?: Maybe<Scalars['String']['output']>;
  dietary_preferences_note?: Maybe<Scalars['String']['output']>;
  dinner_persona?: Maybe<Scalars['String']['output']>;
  dinners?: Maybe<Array<DinnerMatch>>;
  ethnicity?: Maybe<Scalars['String']['output']>;
  events?: Maybe<Array<Event>>;
  experiences?: Maybe<Array<ExperienceTransaction>>;
  extraversion?: Maybe<Scalars['String']['output']>;
  family_status?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  has_children?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  industry?: Maybe<Scalars['String']['output']>;
  interests?: Maybe<Scalars['String']['output']>;
  is_ambassador?: Maybe<Scalars['Boolean']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  match_after_date?: Maybe<Scalars['Date']['output']>;
  membership_charge_period?: Maybe<Scalars['String']['output']>;
  membership_started_at?: Maybe<Scalars['Date']['output']>;
  membership_status?: Maybe<Scalars['String']['output']>;
  nomination_code?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  primary_email?: Maybe<Scalars['String']['output']>;
  rating_arts_culture?: Maybe<Scalars['String']['output']>;
  rating_business?: Maybe<Scalars['String']['output']>;
  rating_health_wellness?: Maybe<Scalars['String']['output']>;
  rating_history?: Maybe<Scalars['String']['output']>;
  rating_literature?: Maybe<Scalars['String']['output']>;
  rating_music_film?: Maybe<Scalars['String']['output']>;
  rating_philosophy?: Maybe<Scalars['String']['output']>;
  rating_politics_events?: Maybe<Scalars['String']['output']>;
  rating_spirituality_religion?: Maybe<Scalars['String']['output']>;
  rating_sports_recreation?: Maybe<Scalars['String']['output']>;
  rating_tech_science?: Maybe<Scalars['String']['output']>;
  referred_by_person?: Maybe<Person>;
  referred_by_person_id?: Maybe<Scalars['String']['output']>;
  referred_by_text?: Maybe<Scalars['String']['output']>;
  risk_tolerance?: Maybe<Scalars['String']['output']>;
  score_conversation_focus?: Maybe<Scalars['Float']['output']>;
  score_energy?: Maybe<Scalars['Float']['output']>;
  score_experience?: Maybe<Scalars['Float']['output']>;
  score_extraversion?: Maybe<Scalars['Float']['output']>;
  score_intention_focus?: Maybe<Scalars['Float']['output']>;
  score_overall?: Maybe<Scalars['Float']['output']>;
  score_slope?: Maybe<Scalars['Float']['output']>;
  score_transactional?: Maybe<Scalars['Float']['output']>;
  score_vulnerability?: Maybe<Scalars['Float']['output']>;
  score_work_life?: Maybe<Scalars['Float']['output']>;
  secondary_email?: Maybe<Scalars['String']['output']>;
  sexual_orientation?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  stripe_customer_id?: Maybe<Scalars['String']['output']>;
  test_account?: Maybe<Scalars['Boolean']['output']>;
  three_words?: Maybe<Scalars['String']['output']>;
  time_in_city?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['Date']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  value_expectation?: Maybe<Scalars['String']['output']>;
  work_description?: Maybe<Scalars['String']['output']>;
};

export type PersonsResponse = {
  persons: Array<Person>;
  totalRecords: Scalars['Float']['output'];
};

export type Query = {
  getDinners: DinnersResponse;
  getExperiences: ExperiencesResponse;
  getFollowupKanbanCards: Array<FollowupKanbanCard>;
  getInterview: Interview;
  getInterviewStats: Array<UserInterviewStats>;
  getInterviews: InterviewsResponse;
  getLoggedInUser: LoggedInUser;
  getManagedAccounts: AccountManagementResponse;
  getPerson: Person;
  getPersonAvailability: Array<Availability>;
  getPersonDinners: Array<DinnerMatch>;
  getPersonExperiences: Array<ExperienceTransaction>;
  getPersonInterview?: Maybe<Interview>;
  getPersons: PersonsResponse;
  getRoles: Array<Role>;
  getStats: Stats;
  getUserLogs: Array<UserLog>;
  getUsers: Array<User>;
};

export type QueryGetDinnersArgs = {
  filters: Array<FilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortingInput>;
};

export type QueryGetExperiencesArgs = {
  filters: Array<FilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortingInput>;
};

export type QueryGetFollowupKanbanCardsArgs = {
  filters: FollowupKanbanCardInputs;
};

export type QueryGetInterviewArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetInterviewStatsArgs = {
  filters: InterviewerStatsInput;
};

export type QueryGetInterviewsArgs = {
  filters: Array<FilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortingInput>;
};

export type QueryGetManagedAccountsArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
  pagination: PaginationInput;
  sort?: InputMaybe<SortingInput>;
};

export type QueryGetPersonArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetPersonAvailabilityArgs = {
  personId: Scalars['String']['input'];
};

export type QueryGetPersonDinnersArgs = {
  personId: Scalars['String']['input'];
};

export type QueryGetPersonExperiencesArgs = {
  personId: Scalars['String']['input'];
};

export type QueryGetPersonInterviewArgs = {
  personId: Scalars['String']['input'];
};

export type QueryGetPersonsArgs = {
  filters: Array<FilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortingInput>;
};

export type QueryGetUserLogsArgs = {
  pagination: PaginationInput;
  personId: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export type Role = {
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type SortingInput = {
  id: Scalars['String']['input'];
  order?: Scalars['String']['input'];
};

export type Stats = {
  interviewStats: Array<UserInterviewStats>;
};

export type Transaction = {
  created_at: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  stripe_payment_intent_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type User = {
  auth_id?: Maybe<Scalars['String']['output']>;
  deleted_at?: Maybe<Scalars['DateTimeISO']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  roles: Array<Role>;
};

export type UserCreateInput = {
  auth_id?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  roles?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UserInterviewStats = {
  closeRate: Scalars['Float']['output'];
  interviewLoss: Scalars['Float']['output'];
  interviewWins: Scalars['Float']['output'];
  interviewerId: Scalars['ID']['output'];
  interviewerName: Scalars['String']['output'];
  subscriptionTypes: Scalars['JSON']['output'];
  totalCompletedInterviews: Scalars['Float']['output'];
  totalInterviews: Scalars['Float']['output'];
};

export type UserLog = {
  content: Scalars['String']['output'];
  created_at: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  person_id: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTimeISO']['output'];
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type UserUpdateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rolesToAdd?: InputMaybe<Array<Scalars['Int']['input']>>;
  rolesToRemove?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type GetManagedAccountQueryVariables = Exact<{
  pagination: PaginationInput;
  sort?: InputMaybe<SortingInput>;
  filters?: InputMaybe<Array<FilterInput> | FilterInput>;
}>;

export type GetManagedAccountQuery = {
  getManagedAccounts: {
    totalRecords: number;
    accounts: Array<{
      id: string;
      dinners_count?: number | null;
      experiences_count?: number | null;
      account_manager_id?: string | null;
      account_manager_name?: string | null;
      person: {
        id: string;
        first_name?: string | null;
        last_name?: string | null;
        status?: string | null;
        phone_number?: string | null;
        primary_email?: string | null;
        city?: string | null;
        membership_status?: string | null;
        membership_started_at?: any | null;
        membership_charge_period?: string | null;
      };
    }>;
  };
};

export type UpsertManagedAccountMutationVariables = Exact<{
  newManagerId: Scalars['String']['input'];
  personId: Scalars['String']['input'];
}>;

export type UpsertManagedAccountMutation = {
  upsertManagedAccount: {
    id: string;
    account_manager_id?: string | null;
    account_manager_name?: string | null;
    person: {
      id: string;
      first_name?: string | null;
      last_name?: string | null;
      phone_number?: string | null;
      primary_email?: string | null;
      city?: string | null;
      membership_status?: string | null;
      membership_started_at?: any | null;
    };
  };
};

export type GetDinnersQueryVariables = Exact<{
  filters: Array<FilterInput> | FilterInput;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortingInput>;
}>;

export type GetDinnersQuery = {
  getDinners: {
    count: number;
    dinners: Array<{
      id: string;
      date: string;
      description?: string | null;
      cost_per_seat?: number | null;
      price_per_seat?: number | null;
      status?: string | null;
      booking_url?: string | null;
      codename: string;
      type?: string | null;
      max_seats?: number | null;
      city?: string | null;
    }>;
  };
};

export type GetExperiencesQueryVariables = Exact<{
  filters: Array<FilterInput> | FilterInput;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortingInput>;
}>;

export type GetExperiencesQuery = {
  getExperiences: {
    count: number;
    experiences: Array<{
      id: string;
      title: string;
      experience_type: string;
      price_per_seat: number;
      short_description?: string | null;
      description?: string | null;
      status: string;
      date: any;
      max_guests_per_member: number;
      max_participants: number;
      tickets_left: number;
      city: string;
      guests_allowed?: boolean | null;
    }>;
  };
};

export type CreateInterviewMutationVariables = Exact<{
  payload: CreateInterview;
}>;

export type CreateInterviewMutation = {
  createInterview: {
    id: string;
    date: any;
    deferred_to?: any | null;
    interviewer_id: string;
    person_id: string;
    follow_up_notes?: string | null;
    status: string;
    notes?: string | null;
    created_at: any;
    updated_at: any;
  };
};

export type GetInterviewQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetInterviewQuery = {
  getInterview: {
    id: string;
    date: any;
    deferred_to?: any | null;
    interviewer_id: string;
    person_id: string;
    follow_up_notes?: string | null;
    status: string;
    notes?: string | null;
    created_at: any;
    updated_at: any;
    person: {
      id: string;
      accomplishments?: string | null;
      access_requirements?: string | null;
      age_matching_preference?: string | null;
      airtable_id?: string | null;
      application_age_range?: string | null;
      application_dinner_guest?: string | null;
      match_after_date?: any | null;
      archetype?: string | null;
      avatar_path?: string | null;
      city?: string | null;
      connection_expectation?: string | null;
      credit_balance?: number | null;
      created_at?: any | null;
      date_of_birth?: any | null;
      dietary_preferences?: string | null;
      dietary_preferences_note?: string | null;
      dinner_persona?: string | null;
      ethnicity?: string | null;
      extraversion?: string | null;
      family_status?: string | null;
      area_of_study?: string | null;
      first_name?: string | null;
      gender?: string | null;
      has_children?: boolean | null;
      industry?: string | null;
      interests?: string | null;
      is_ambassador?: boolean | null;
      last_name?: string | null;
      membership_charge_period?: string | null;
      membership_started_at?: any | null;
      membership_status?: string | null;
      nomination_code?: string | null;
      phone_number?: string | null;
      primary_email?: string | null;
      rating_arts_culture?: string | null;
      rating_business?: string | null;
      rating_health_wellness?: string | null;
      rating_history?: string | null;
      rating_literature?: string | null;
      rating_music_film?: string | null;
      rating_philosophy?: string | null;
      rating_politics_events?: string | null;
      rating_sports_recreation?: string | null;
      rating_spirituality_religion?: string | null;
      rating_tech_science?: string | null;
      referred_by_person_id?: string | null;
      referred_by_text?: string | null;
      risk_tolerance?: string | null;
      score_conversation_focus?: number | null;
      score_energy?: number | null;
      score_experience?: number | null;
      score_extraversion?: number | null;
      score_intention_focus?: number | null;
      score_overall?: number | null;
      score_slope?: number | null;
      score_transactional?: number | null;
      score_vulnerability?: number | null;
      score_work_life?: number | null;
      secondary_email?: string | null;
      sexual_orientation?: string | null;
      status?: string | null;
      stripe_customer_id?: string | null;
      test_account?: boolean | null;
      three_words?: string | null;
      time_in_city?: string | null;
      updated_at?: any | null;
      user_id?: string | null;
      value_expectation?: string | null;
      work_description?: string | null;
      referred_by_person?: {
        id: string;
        first_name?: string | null;
        last_name?: string | null;
      } | null;
    };
    interviewer: {
      auth_id?: string | null;
      email: string;
      name: string;
      id: string;
      roles: Array<{ id: number; name: string }>;
    };
  };
};

export type GetInterviewsQueryVariables = Exact<{
  filters: Array<FilterInput> | FilterInput;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortingInput>;
}>;

export type GetInterviewsQuery = {
  getInterviews: {
    totalRecords: number;
    interviews: Array<{
      id: string;
      date: any;
      deferred_to?: any | null;
      interviewer_id: string;
      person_id: string;
      follow_up_notes?: string | null;
      status: string;
      notes?: string | null;
      created_at: any;
      updated_at: any;
      person: {
        id: string;
        accomplishments?: string | null;
        access_requirements?: string | null;
        age_matching_preference?: string | null;
        airtable_id?: string | null;
        application_age_range?: string | null;
        application_dinner_guest?: string | null;
        match_after_date?: any | null;
        archetype?: string | null;
        avatar_path?: string | null;
        city?: string | null;
        connection_expectation?: string | null;
        credit_balance?: number | null;
        created_at?: any | null;
        date_of_birth?: any | null;
        dietary_preferences?: string | null;
        dietary_preferences_note?: string | null;
        dinner_persona?: string | null;
        ethnicity?: string | null;
        extraversion?: string | null;
        family_status?: string | null;
        area_of_study?: string | null;
        first_name?: string | null;
        gender?: string | null;
        has_children?: boolean | null;
        industry?: string | null;
        interests?: string | null;
        is_ambassador?: boolean | null;
        last_name?: string | null;
        membership_charge_period?: string | null;
        membership_started_at?: any | null;
        membership_status?: string | null;
        nomination_code?: string | null;
        phone_number?: string | null;
        primary_email?: string | null;
        rating_arts_culture?: string | null;
        rating_business?: string | null;
        rating_health_wellness?: string | null;
        rating_history?: string | null;
        rating_literature?: string | null;
        rating_music_film?: string | null;
        rating_philosophy?: string | null;
        rating_politics_events?: string | null;
        rating_sports_recreation?: string | null;
        rating_spirituality_religion?: string | null;
        rating_tech_science?: string | null;
        referred_by_person_id?: string | null;
        referred_by_text?: string | null;
        risk_tolerance?: string | null;
        score_conversation_focus?: number | null;
        score_energy?: number | null;
        score_experience?: number | null;
        score_extraversion?: number | null;
        score_intention_focus?: number | null;
        score_overall?: number | null;
        score_slope?: number | null;
        score_transactional?: number | null;
        score_vulnerability?: number | null;
        score_work_life?: number | null;
        secondary_email?: string | null;
        sexual_orientation?: string | null;
        status?: string | null;
        stripe_customer_id?: string | null;
        test_account?: boolean | null;
        three_words?: string | null;
        time_in_city?: string | null;
        updated_at?: any | null;
        user_id?: string | null;
        value_expectation?: string | null;
        work_description?: string | null;
        referred_by_person?: {
          id: string;
          first_name?: string | null;
          last_name?: string | null;
        } | null;
      };
      interviewer: {
        auth_id?: string | null;
        email: string;
        name: string;
        id: string;
        roles: Array<{ id: number; name: string }>;
      };
    }>;
  };
};

export type PatchInterviewMutationVariables = Exact<{
  id: Scalars['String']['input'];
  payload: PatchInterview;
}>;

export type PatchInterviewMutation = {
  patchInterview: {
    id: string;
    date: any;
    deferred_to?: any | null;
    interviewer_id: string;
    person_id: string;
    follow_up_notes?: string | null;
    status: string;
    notes?: string | null;
    created_at: any;
    updated_at: any;
  };
};

export type CreateKanbanCardMutationVariables = Exact<{
  payload: CreateKanbanCardInput;
}>;

export type CreateKanbanCardMutation = { createKanbanCards: { id: string } };

export type GetFollowupKanbanCardsQueryVariables = Exact<{
  filters: FollowupKanbanCardInputs;
}>;

export type GetFollowupKanbanCardsQuery = {
  getFollowupKanbanCards: Array<{
    id: string;
    status: string;
    created_at: any;
    updated_at: any;
    interview: {
      id: string;
      follow_up_notes?: string | null;
      notes?: string | null;
      date: any;
      person: {
        id: string;
        first_name?: string | null;
        last_name?: string | null;
        city?: string | null;
        primary_email?: string | null;
        phone_number?: string | null;
        status?: string | null;
      };
      interviewer: { id: string; name: string };
    };
  }>;
};

export type PatchFollowupKanbanCardMutationVariables = Exact<{
  id: Scalars['String']['input'];
  payload: PatchFollowupKanbanCardInput;
}>;

export type PatchFollowupKanbanCardMutation = {
  patchFollowupKanbanCards: {
    id: string;
    status: string;
    interview: { follow_up_notes?: string | null; notes?: string | null };
  };
};

export type GetPersonQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPersonQuery = {
  getPerson: {
    id: string;
    accomplishments?: string | null;
    access_requirements?: string | null;
    age_matching_preference?: string | null;
    airtable_id?: string | null;
    application_age_range?: string | null;
    application_dinner_guest?: string | null;
    match_after_date?: any | null;
    archetype?: string | null;
    avatar_path?: string | null;
    city?: string | null;
    connection_expectation?: string | null;
    credit_balance?: number | null;
    created_at?: any | null;
    date_of_birth?: any | null;
    dietary_preferences?: string | null;
    dietary_preferences_note?: string | null;
    dinner_persona?: string | null;
    ethnicity?: string | null;
    extraversion?: string | null;
    family_status?: string | null;
    area_of_study?: string | null;
    first_name?: string | null;
    gender?: string | null;
    has_children?: boolean | null;
    industry?: string | null;
    interests?: string | null;
    is_ambassador?: boolean | null;
    last_name?: string | null;
    membership_charge_period?: string | null;
    membership_started_at?: any | null;
    membership_status?: string | null;
    nomination_code?: string | null;
    phone_number?: string | null;
    primary_email?: string | null;
    rating_arts_culture?: string | null;
    rating_business?: string | null;
    rating_health_wellness?: string | null;
    rating_history?: string | null;
    rating_literature?: string | null;
    rating_music_film?: string | null;
    rating_philosophy?: string | null;
    rating_politics_events?: string | null;
    rating_sports_recreation?: string | null;
    rating_spirituality_religion?: string | null;
    rating_tech_science?: string | null;
    referred_by_person_id?: string | null;
    referred_by_text?: string | null;
    risk_tolerance?: string | null;
    score_conversation_focus?: number | null;
    score_energy?: number | null;
    score_experience?: number | null;
    score_extraversion?: number | null;
    score_intention_focus?: number | null;
    score_overall?: number | null;
    score_slope?: number | null;
    score_transactional?: number | null;
    score_vulnerability?: number | null;
    score_work_life?: number | null;
    secondary_email?: string | null;
    sexual_orientation?: string | null;
    status?: string | null;
    stripe_customer_id?: string | null;
    test_account?: boolean | null;
    three_words?: string | null;
    time_in_city?: string | null;
    updated_at?: any | null;
    user_id?: string | null;
    value_expectation?: string | null;
    work_description?: string | null;
    dinners?: Array<{
      id: string;
      status: string;
      stripe_payment_intent_id?: string | null;
      location?: {
        id: string;
        name?: string | null;
        contact_email?: string | null;
        contact_name?: string | null;
        contact_phone_number?: string | null;
        cuisine?: string | null;
        street_address?: string | null;
        neighborhood?: string | null;
        dress_code?: string | null;
        parking_details?: string | null;
        menu?: string | null;
        arrival_instructions?: string | null;
      } | null;
      dinner?: {
        id: string;
        created_at: any;
        updated_at: any;
        codename: string;
        date: any;
        location_id?: string | null;
        type?: string | null;
        booking_url?: string | null;
        cost_per_seat?: number | null;
        price_per_seat?: number | null;
        airtable_id: string;
        description?: string | null;
        status?: string | null;
      } | null;
    }> | null;
    experiences?: Array<{
      id: string;
      ticket_count?: number | null;
      transaction: { status?: string | null; stripe_payment_intent_id?: string | null };
      experience?: {
        title: string;
        cost_per_seat: number;
        price_per_seat: number;
        description?: string | null;
        status: string;
        experience_type: string;
        experience_category?: string | null;
        date: any;
      } | null;
      location?: {
        id: string;
        name?: string | null;
        contact_email?: string | null;
        contact_name?: string | null;
        contact_phone_number?: string | null;
        cuisine?: string | null;
        street_address?: string | null;
        neighborhood?: string | null;
        dress_code?: string | null;
        parking_details?: string | null;
        menu?: string | null;
        arrival_instructions?: string | null;
      } | null;
    }> | null;
    account_management?: {
      account_manager_id?: string | null;
      account_manager_name?: string | null;
      notes?: string | null;
    } | null;
    events?: Array<{
      created_at: any;
      person_id?: string | null;
      type?: string | null;
      dinner_id?: string | null;
      metadata?: any | null;
    }> | null;
    referred_by_person?: {
      id: string;
      first_name?: string | null;
      last_name?: string | null;
    } | null;
  };
};

export type GetPersonAvailabilityQueryVariables = Exact<{
  personId: Scalars['String']['input'];
}>;

export type GetPersonAvailabilityQuery = {
  getPersonAvailability: Array<{
    id: string;
    city: string;
    date: any;
    person_id: string;
    created_at: any;
    updated_at: any;
  }>;
};

export type GetPersonDinnersQueryVariables = Exact<{
  personId: Scalars['String']['input'];
}>;

export type GetPersonDinnersQuery = {
  getPersonDinners: Array<{
    id: string;
    person_id: string;
    matched_dinner_id: string;
    status: string;
    stripe_payment_intent_id?: string | null;
    dinner?: {
      id: string;
      date: any;
      description?: string | null;
      cost_per_seat?: number | null;
      price_per_seat?: number | null;
      status?: string | null;
      booking_url?: string | null;
      codename: string;
      type?: string | null;
    } | null;
    location?: {
      city?: string | null;
      name?: string | null;
      contact_email?: string | null;
      contact_name?: string | null;
      contact_phone_number?: string | null;
      street_address?: string | null;
      arrival_instructions?: string | null;
      cuisine?: string | null;
      max_capacity?: number | null;
      min_capacity?: number | null;
      menu?: string | null;
      dress_code?: string | null;
    } | null;
  }>;
};

export type GetPersonExperiencesQueryVariables = Exact<{
  personId: Scalars['String']['input'];
}>;

export type GetPersonExperiencesQuery = {
  getPersonExperiences: Array<{
    id: string;
    person_id: string;
    experience_id: string;
    ticket_count?: number | null;
    created_at: any;
    transaction_id: string;
    transaction: {
      id: string;
      status?: string | null;
      stripe_payment_intent_id?: string | null;
      description?: string | null;
    };
    experience?: { id: string; title: string; experience_type: string; date: any } | null;
    location?: {
      id: string;
      name?: string | null;
      street_address?: string | null;
      city?: string | null;
      cuisine?: string | null;
    } | null;
    feedback?: { id: string; feedback?: string | null; overall_rating?: string | null } | null;
  }>;
};

export type GetPersonInterviewQueryVariables = Exact<{
  personId: Scalars['String']['input'];
}>;

export type GetPersonInterviewQuery = {
  getPersonInterview?: {
    id: string;
    date: any;
    deferred_to?: any | null;
    interviewer_id: string;
    person_id: string;
    follow_up_notes?: string | null;
    status: string;
    notes?: string | null;
    created_at: any;
    updated_at: any;
    person: {
      id: string;
      accomplishments?: string | null;
      access_requirements?: string | null;
      age_matching_preference?: string | null;
      airtable_id?: string | null;
      application_age_range?: string | null;
      application_dinner_guest?: string | null;
      match_after_date?: any | null;
      archetype?: string | null;
      avatar_path?: string | null;
      city?: string | null;
      connection_expectation?: string | null;
      credit_balance?: number | null;
      created_at?: any | null;
      date_of_birth?: any | null;
      dietary_preferences?: string | null;
      dietary_preferences_note?: string | null;
      dinner_persona?: string | null;
      ethnicity?: string | null;
      extraversion?: string | null;
      family_status?: string | null;
      area_of_study?: string | null;
      first_name?: string | null;
      gender?: string | null;
      has_children?: boolean | null;
      industry?: string | null;
      interests?: string | null;
      is_ambassador?: boolean | null;
      last_name?: string | null;
      membership_charge_period?: string | null;
      membership_started_at?: any | null;
      membership_status?: string | null;
      nomination_code?: string | null;
      phone_number?: string | null;
      primary_email?: string | null;
      rating_arts_culture?: string | null;
      rating_business?: string | null;
      rating_health_wellness?: string | null;
      rating_history?: string | null;
      rating_literature?: string | null;
      rating_music_film?: string | null;
      rating_philosophy?: string | null;
      rating_politics_events?: string | null;
      rating_sports_recreation?: string | null;
      rating_spirituality_religion?: string | null;
      rating_tech_science?: string | null;
      referred_by_person_id?: string | null;
      referred_by_text?: string | null;
      risk_tolerance?: string | null;
      score_conversation_focus?: number | null;
      score_energy?: number | null;
      score_experience?: number | null;
      score_extraversion?: number | null;
      score_intention_focus?: number | null;
      score_overall?: number | null;
      score_slope?: number | null;
      score_transactional?: number | null;
      score_vulnerability?: number | null;
      score_work_life?: number | null;
      secondary_email?: string | null;
      sexual_orientation?: string | null;
      status?: string | null;
      stripe_customer_id?: string | null;
      test_account?: boolean | null;
      three_words?: string | null;
      time_in_city?: string | null;
      updated_at?: any | null;
      user_id?: string | null;
      value_expectation?: string | null;
      work_description?: string | null;
      referred_by_person?: {
        id: string;
        first_name?: string | null;
        last_name?: string | null;
      } | null;
    };
    interviewer: { name: string };
  } | null;
};

export type GetPersonsQueryVariables = Exact<{
  filters: Array<FilterInput> | FilterInput;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortingInput>;
}>;

export type GetPersonsQuery = {
  getPersons: {
    totalRecords: number;
    persons: Array<{
      id: string;
      accomplishments?: string | null;
      access_requirements?: string | null;
      age_matching_preference?: string | null;
      airtable_id?: string | null;
      application_age_range?: string | null;
      application_dinner_guest?: string | null;
      match_after_date?: any | null;
      archetype?: string | null;
      avatar_path?: string | null;
      city?: string | null;
      connection_expectation?: string | null;
      credit_balance?: number | null;
      created_at?: any | null;
      date_of_birth?: any | null;
      dietary_preferences?: string | null;
      dietary_preferences_note?: string | null;
      dinner_persona?: string | null;
      ethnicity?: string | null;
      extraversion?: string | null;
      family_status?: string | null;
      area_of_study?: string | null;
      first_name?: string | null;
      gender?: string | null;
      has_children?: boolean | null;
      industry?: string | null;
      interests?: string | null;
      is_ambassador?: boolean | null;
      last_name?: string | null;
      membership_charge_period?: string | null;
      membership_started_at?: any | null;
      membership_status?: string | null;
      nomination_code?: string | null;
      phone_number?: string | null;
      primary_email?: string | null;
      rating_arts_culture?: string | null;
      rating_business?: string | null;
      rating_health_wellness?: string | null;
      rating_history?: string | null;
      rating_literature?: string | null;
      rating_music_film?: string | null;
      rating_philosophy?: string | null;
      rating_politics_events?: string | null;
      rating_sports_recreation?: string | null;
      rating_spirituality_religion?: string | null;
      rating_tech_science?: string | null;
      referred_by_person_id?: string | null;
      referred_by_text?: string | null;
      risk_tolerance?: string | null;
      score_conversation_focus?: number | null;
      score_energy?: number | null;
      score_experience?: number | null;
      score_extraversion?: number | null;
      score_intention_focus?: number | null;
      score_overall?: number | null;
      score_slope?: number | null;
      score_transactional?: number | null;
      score_vulnerability?: number | null;
      score_work_life?: number | null;
      secondary_email?: string | null;
      sexual_orientation?: string | null;
      status?: string | null;
      stripe_customer_id?: string | null;
      test_account?: boolean | null;
      three_words?: string | null;
      time_in_city?: string | null;
      updated_at?: any | null;
      user_id?: string | null;
      value_expectation?: string | null;
      work_description?: string | null;
      referred_by_person?: {
        id: string;
        first_name?: string | null;
        last_name?: string | null;
      } | null;
    }>;
  };
};

export type PatchPersonMutationVariables = Exact<{
  id: Scalars['String']['input'];
  payload: PatchPerson;
}>;

export type PatchPersonMutation = {
  patchPerson: {
    id: string;
    accomplishments?: string | null;
    access_requirements?: string | null;
    age_matching_preference?: string | null;
    airtable_id?: string | null;
    application_age_range?: string | null;
    application_dinner_guest?: string | null;
    match_after_date?: any | null;
    archetype?: string | null;
    avatar_path?: string | null;
    city?: string | null;
    connection_expectation?: string | null;
    credit_balance?: number | null;
    created_at?: any | null;
    date_of_birth?: any | null;
    dietary_preferences?: string | null;
    dietary_preferences_note?: string | null;
    dinner_persona?: string | null;
    ethnicity?: string | null;
    extraversion?: string | null;
    family_status?: string | null;
    area_of_study?: string | null;
    first_name?: string | null;
    gender?: string | null;
    has_children?: boolean | null;
    industry?: string | null;
    interests?: string | null;
    is_ambassador?: boolean | null;
    last_name?: string | null;
    membership_charge_period?: string | null;
    membership_started_at?: any | null;
    membership_status?: string | null;
    nomination_code?: string | null;
    phone_number?: string | null;
    primary_email?: string | null;
    rating_arts_culture?: string | null;
    rating_business?: string | null;
    rating_health_wellness?: string | null;
    rating_history?: string | null;
    rating_literature?: string | null;
    rating_music_film?: string | null;
    rating_philosophy?: string | null;
    rating_politics_events?: string | null;
    rating_sports_recreation?: string | null;
    rating_spirituality_religion?: string | null;
    rating_tech_science?: string | null;
    referred_by_person_id?: string | null;
    referred_by_text?: string | null;
    risk_tolerance?: string | null;
    score_conversation_focus?: number | null;
    score_energy?: number | null;
    score_experience?: number | null;
    score_extraversion?: number | null;
    score_intention_focus?: number | null;
    score_overall?: number | null;
    score_slope?: number | null;
    score_transactional?: number | null;
    score_vulnerability?: number | null;
    score_work_life?: number | null;
    secondary_email?: string | null;
    sexual_orientation?: string | null;
    status?: string | null;
    stripe_customer_id?: string | null;
    test_account?: boolean | null;
    three_words?: string | null;
    time_in_city?: string | null;
    updated_at?: any | null;
    user_id?: string | null;
    value_expectation?: string | null;
    work_description?: string | null;
    referred_by_person?: {
      id: string;
      first_name?: string | null;
      last_name?: string | null;
    } | null;
  };
};

export type PersonDinnerFragmentFragment = {
  id: string;
  status: string;
  stripe_payment_intent_id?: string | null;
  location?: {
    id: string;
    name?: string | null;
    contact_email?: string | null;
    contact_name?: string | null;
    contact_phone_number?: string | null;
    cuisine?: string | null;
    street_address?: string | null;
    neighborhood?: string | null;
    dress_code?: string | null;
    parking_details?: string | null;
    menu?: string | null;
    arrival_instructions?: string | null;
  } | null;
  dinner?: {
    id: string;
    created_at: any;
    updated_at: any;
    codename: string;
    date: any;
    location_id?: string | null;
    type?: string | null;
    booking_url?: string | null;
    cost_per_seat?: number | null;
    price_per_seat?: number | null;
    airtable_id: string;
    description?: string | null;
    status?: string | null;
  } | null;
};

export type PersonExperienceFragmentFragment = {
  id: string;
  ticket_count?: number | null;
  transaction: { status?: string | null; stripe_payment_intent_id?: string | null };
  experience?: {
    title: string;
    cost_per_seat: number;
    price_per_seat: number;
    description?: string | null;
    status: string;
    experience_type: string;
    experience_category?: string | null;
    date: any;
  } | null;
  location?: {
    id: string;
    name?: string | null;
    contact_email?: string | null;
    contact_name?: string | null;
    contact_phone_number?: string | null;
    cuisine?: string | null;
    street_address?: string | null;
    neighborhood?: string | null;
    dress_code?: string | null;
    parking_details?: string | null;
    menu?: string | null;
    arrival_instructions?: string | null;
  } | null;
};

export type PersonFragmentFragment = {
  id: string;
  accomplishments?: string | null;
  access_requirements?: string | null;
  age_matching_preference?: string | null;
  airtable_id?: string | null;
  application_age_range?: string | null;
  application_dinner_guest?: string | null;
  match_after_date?: any | null;
  archetype?: string | null;
  avatar_path?: string | null;
  city?: string | null;
  connection_expectation?: string | null;
  credit_balance?: number | null;
  created_at?: any | null;
  date_of_birth?: any | null;
  dietary_preferences?: string | null;
  dietary_preferences_note?: string | null;
  dinner_persona?: string | null;
  ethnicity?: string | null;
  extraversion?: string | null;
  family_status?: string | null;
  area_of_study?: string | null;
  first_name?: string | null;
  gender?: string | null;
  has_children?: boolean | null;
  industry?: string | null;
  interests?: string | null;
  is_ambassador?: boolean | null;
  last_name?: string | null;
  membership_charge_period?: string | null;
  membership_started_at?: any | null;
  membership_status?: string | null;
  nomination_code?: string | null;
  phone_number?: string | null;
  primary_email?: string | null;
  rating_arts_culture?: string | null;
  rating_business?: string | null;
  rating_health_wellness?: string | null;
  rating_history?: string | null;
  rating_literature?: string | null;
  rating_music_film?: string | null;
  rating_philosophy?: string | null;
  rating_politics_events?: string | null;
  rating_sports_recreation?: string | null;
  rating_spirituality_religion?: string | null;
  rating_tech_science?: string | null;
  referred_by_person_id?: string | null;
  referred_by_text?: string | null;
  risk_tolerance?: string | null;
  score_conversation_focus?: number | null;
  score_energy?: number | null;
  score_experience?: number | null;
  score_extraversion?: number | null;
  score_intention_focus?: number | null;
  score_overall?: number | null;
  score_slope?: number | null;
  score_transactional?: number | null;
  score_vulnerability?: number | null;
  score_work_life?: number | null;
  secondary_email?: string | null;
  sexual_orientation?: string | null;
  status?: string | null;
  stripe_customer_id?: string | null;
  test_account?: boolean | null;
  three_words?: string | null;
  time_in_city?: string | null;
  updated_at?: any | null;
  user_id?: string | null;
  value_expectation?: string | null;
  work_description?: string | null;
  referred_by_person?: { id: string; first_name?: string | null; last_name?: string | null } | null;
};

export type UpdateMembershipStatusMutationVariables = Exact<{
  id: Scalars['String']['input'];
  status: Scalars['String']['input'];
  payload: Scalars['JSON']['input'];
}>;

export type UpdateMembershipStatusMutation = { updateMembershipStatus: string };

export type GetRolesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRolesQuery = { getRoles: Array<{ id: number; name: string }> };

export type GetStatsQueryVariables = Exact<{
  filters: InterviewerStatsInput;
}>;

export type GetStatsQuery = {
  getInterviewStats: Array<{
    interviewerId: string;
    interviewerName: string;
    totalInterviews: number;
    totalCompletedInterviews: number;
    interviewWins: number;
    interviewLoss: number;
    closeRate: number;
    subscriptionTypes: any;
  }>;
};

export type CreateUserMutationVariables = Exact<{
  input: UserCreateInput;
}>;

export type CreateUserMutation = {
  createUser: {
    id: string;
    auth_id?: string | null;
    email: string;
    name: string;
    roles: Array<{ id: number; name: string }>;
  };
};

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteUserMutation = { deleteUser: boolean };

export type GetLoggedInUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetLoggedInUserQuery = {
  getLoggedInUser: {
    auth_id?: string | null;
    email: string;
    name: string;
    id: string;
    roles: Array<{ id: number; name: string }>;
  };
};

export type GetUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsersQuery = {
  getUsers: Array<{
    id: string;
    email: string;
    name: string;
    roles: Array<{ id: number; name: string }>;
  }>;
};

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UserUpdateInput;
}>;

export type UpdateUserMutation = {
  updateUser: {
    id: string;
    auth_id?: string | null;
    email: string;
    name: string;
    roles: Array<{ id: number; name: string }>;
  };
};

export type CreateUserLogMutationVariables = Exact<{
  input: CreateUserLogInput;
}>;

export type CreateUserLogMutation = {
  createUserLog: {
    id: string;
    person_id: string;
    user_id?: string | null;
    created_at: any;
    updated_at: any;
    content: string;
  };
};

export type GetUserLogsQueryVariables = Exact<{
  type?: InputMaybe<Scalars['String']['input']>;
  personId: Scalars['String']['input'];
  pagination: PaginationInput;
}>;

export type GetUserLogsQuery = {
  getUserLogs: Array<{
    id: string;
    person_id: string;
    user_id?: string | null;
    created_at: any;
    updated_at: any;
    content: string;
    user?: { id: string; name: string } | null;
  }>;
};

export const PersonDinnerFragmentFragmentDoc = gql`
  fragment PersonDinnerFragment on DinnerMatch {
    id
    status
    stripe_payment_intent_id
    location {
      id
      name
      contact_email
      contact_name
      contact_phone_number
      cuisine
      street_address
      neighborhood
      dress_code
      parking_details
      menu
      arrival_instructions
    }
    dinner {
      id
      created_at
      updated_at
      codename
      date
      location_id
      type
      booking_url
      cost_per_seat
      price_per_seat
      airtable_id
      description
      status
    }
  }
`;
export const PersonExperienceFragmentFragmentDoc = gql`
  fragment PersonExperienceFragment on ExperienceTransaction {
    id
    ticket_count
    transaction {
      status
      stripe_payment_intent_id
    }
    experience {
      title
      cost_per_seat
      price_per_seat
      description
      status
      experience_type
      experience_category
      date
    }
    location {
      id
      name
      contact_email
      contact_name
      contact_phone_number
      cuisine
      street_address
      neighborhood
      dress_code
      parking_details
      menu
      arrival_instructions
    }
  }
`;
export const PersonFragmentFragmentDoc = gql`
  fragment PersonFragment on Person {
    id
    accomplishments
    access_requirements
    age_matching_preference
    airtable_id
    application_age_range
    application_dinner_guest
    match_after_date
    archetype
    avatar_path
    access_requirements
    city
    connection_expectation
    credit_balance
    created_at
    date_of_birth
    dietary_preferences
    dietary_preferences_note
    dinner_persona
    ethnicity
    extraversion
    family_status
    area_of_study
    first_name
    gender
    has_children
    industry
    interests
    is_ambassador
    last_name
    membership_charge_period
    membership_started_at
    membership_status
    nomination_code
    phone_number
    primary_email
    rating_arts_culture
    rating_business
    rating_health_wellness
    rating_history
    rating_literature
    rating_music_film
    rating_philosophy
    rating_politics_events
    rating_sports_recreation
    rating_spirituality_religion
    rating_tech_science
    referred_by_person_id
    referred_by_text
    risk_tolerance
    score_conversation_focus
    score_energy
    score_experience
    score_extraversion
    score_intention_focus
    score_overall
    score_slope
    score_transactional
    score_vulnerability
    score_work_life
    secondary_email
    sexual_orientation
    status
    stripe_customer_id
    test_account
    three_words
    time_in_city
    updated_at
    user_id
    value_expectation
    work_description
    referred_by_person {
      id
      first_name
      last_name
    }
  }
`;
export const GetManagedAccountDocument = gql`
  query GetManagedAccount(
    $pagination: PaginationInput!
    $sort: SortingInput
    $filters: [FilterInput!]
  ) {
    getManagedAccounts(pagination: $pagination, sort: $sort, filters: $filters) {
      accounts {
        id
        person {
          id
          first_name
          last_name
          status
          phone_number
          primary_email
          city
          membership_status
          membership_started_at
          membership_charge_period
        }
        dinners_count
        experiences_count
        account_manager_id
        account_manager_name
      }
      totalRecords
    }
  }
`;

/**
 * __useGetManagedAccountQuery__
 *
 * To run a query within a React component, call `useGetManagedAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagedAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagedAccountQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetManagedAccountQuery(
  baseOptions: Apollo.QueryHookOptions<GetManagedAccountQuery, GetManagedAccountQueryVariables> &
    ({ variables: GetManagedAccountQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManagedAccountQuery, GetManagedAccountQueryVariables>(
    GetManagedAccountDocument,
    options,
  );
}
export function useGetManagedAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagedAccountQuery,
    GetManagedAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManagedAccountQuery, GetManagedAccountQueryVariables>(
    GetManagedAccountDocument,
    options,
  );
}
export function useGetManagedAccountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetManagedAccountQuery, GetManagedAccountQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetManagedAccountQuery, GetManagedAccountQueryVariables>(
    GetManagedAccountDocument,
    options,
  );
}
export type GetManagedAccountQueryHookResult = ReturnType<typeof useGetManagedAccountQuery>;
export type GetManagedAccountLazyQueryHookResult = ReturnType<typeof useGetManagedAccountLazyQuery>;
export type GetManagedAccountSuspenseQueryHookResult = ReturnType<
  typeof useGetManagedAccountSuspenseQuery
>;
export type GetManagedAccountQueryResult = Apollo.QueryResult<
  GetManagedAccountQuery,
  GetManagedAccountQueryVariables
>;
export const UpsertManagedAccountDocument = gql`
  mutation UpsertManagedAccount($newManagerId: String!, $personId: String!) {
    upsertManagedAccount(newManagerId: $newManagerId, personId: $personId) {
      id
      person {
        id
        first_name
        last_name
        phone_number
        primary_email
        city
        membership_status
        membership_started_at
      }
      account_manager_id
      account_manager_name
    }
  }
`;
export type UpsertManagedAccountMutationFn = Apollo.MutationFunction<
  UpsertManagedAccountMutation,
  UpsertManagedAccountMutationVariables
>;

/**
 * __useUpsertManagedAccountMutation__
 *
 * To run a mutation, you first call `useUpsertManagedAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertManagedAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertManagedAccountMutation, { data, loading, error }] = useUpsertManagedAccountMutation({
 *   variables: {
 *      newManagerId: // value for 'newManagerId'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useUpsertManagedAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertManagedAccountMutation,
    UpsertManagedAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertManagedAccountMutation, UpsertManagedAccountMutationVariables>(
    UpsertManagedAccountDocument,
    options,
  );
}
export type UpsertManagedAccountMutationHookResult = ReturnType<
  typeof useUpsertManagedAccountMutation
>;
export type UpsertManagedAccountMutationResult =
  Apollo.MutationResult<UpsertManagedAccountMutation>;
export type UpsertManagedAccountMutationOptions = Apollo.BaseMutationOptions<
  UpsertManagedAccountMutation,
  UpsertManagedAccountMutationVariables
>;
export const GetDinnersDocument = gql`
  query getDinners($filters: [FilterInput!]!, $pagination: PaginationInput, $sort: SortingInput) {
    getDinners(filters: $filters, pagination: $pagination, sort: $sort) {
      count
      dinners {
        id
        date
        description
        cost_per_seat
        price_per_seat
        status
        booking_url
        codename
        type
        max_seats
        city
      }
    }
  }
`;

/**
 * __useGetDinnersQuery__
 *
 * To run a query within a React component, call `useGetDinnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDinnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDinnersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetDinnersQuery(
  baseOptions: Apollo.QueryHookOptions<GetDinnersQuery, GetDinnersQueryVariables> &
    ({ variables: GetDinnersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDinnersQuery, GetDinnersQueryVariables>(GetDinnersDocument, options);
}
export function useGetDinnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDinnersQuery, GetDinnersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDinnersQuery, GetDinnersQueryVariables>(
    GetDinnersDocument,
    options,
  );
}
export function useGetDinnersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetDinnersQuery, GetDinnersQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetDinnersQuery, GetDinnersQueryVariables>(
    GetDinnersDocument,
    options,
  );
}
export type GetDinnersQueryHookResult = ReturnType<typeof useGetDinnersQuery>;
export type GetDinnersLazyQueryHookResult = ReturnType<typeof useGetDinnersLazyQuery>;
export type GetDinnersSuspenseQueryHookResult = ReturnType<typeof useGetDinnersSuspenseQuery>;
export type GetDinnersQueryResult = Apollo.QueryResult<GetDinnersQuery, GetDinnersQueryVariables>;
export const GetExperiencesDocument = gql`
  query GetExperiences(
    $filters: [FilterInput!]!
    $pagination: PaginationInput
    $sort: SortingInput
  ) {
    getExperiences(filters: $filters, pagination: $pagination, sort: $sort) {
      count
      experiences {
        id
        title
        experience_type
        price_per_seat
        short_description
        description
        status
        date
        max_guests_per_member
        max_participants
        tickets_left
        city
        guests_allowed
      }
    }
  }
`;

/**
 * __useGetExperiencesQuery__
 *
 * To run a query within a React component, call `useGetExperiencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExperiencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExperiencesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetExperiencesQuery(
  baseOptions: Apollo.QueryHookOptions<GetExperiencesQuery, GetExperiencesQueryVariables> &
    ({ variables: GetExperiencesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExperiencesQuery, GetExperiencesQueryVariables>(
    GetExperiencesDocument,
    options,
  );
}
export function useGetExperiencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExperiencesQuery, GetExperiencesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExperiencesQuery, GetExperiencesQueryVariables>(
    GetExperiencesDocument,
    options,
  );
}
export function useGetExperiencesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetExperiencesQuery, GetExperiencesQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetExperiencesQuery, GetExperiencesQueryVariables>(
    GetExperiencesDocument,
    options,
  );
}
export type GetExperiencesQueryHookResult = ReturnType<typeof useGetExperiencesQuery>;
export type GetExperiencesLazyQueryHookResult = ReturnType<typeof useGetExperiencesLazyQuery>;
export type GetExperiencesSuspenseQueryHookResult = ReturnType<
  typeof useGetExperiencesSuspenseQuery
>;
export type GetExperiencesQueryResult = Apollo.QueryResult<
  GetExperiencesQuery,
  GetExperiencesQueryVariables
>;
export const CreateInterviewDocument = gql`
  mutation CreateInterview($payload: CreateInterview!) {
    createInterview(payload: $payload) {
      id
      date
      deferred_to
      interviewer_id
      person_id
      follow_up_notes
      deferred_to
      status
      notes
      created_at
      updated_at
    }
  }
`;
export type CreateInterviewMutationFn = Apollo.MutationFunction<
  CreateInterviewMutation,
  CreateInterviewMutationVariables
>;

/**
 * __useCreateInterviewMutation__
 *
 * To run a mutation, you first call `useCreateInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInterviewMutation, { data, loading, error }] = useCreateInterviewMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateInterviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInterviewMutation,
    CreateInterviewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInterviewMutation, CreateInterviewMutationVariables>(
    CreateInterviewDocument,
    options,
  );
}
export type CreateInterviewMutationHookResult = ReturnType<typeof useCreateInterviewMutation>;
export type CreateInterviewMutationResult = Apollo.MutationResult<CreateInterviewMutation>;
export type CreateInterviewMutationOptions = Apollo.BaseMutationOptions<
  CreateInterviewMutation,
  CreateInterviewMutationVariables
>;
export const GetInterviewDocument = gql`
  query GetInterview($id: String!) {
    getInterview(id: $id) {
      id
      date
      deferred_to
      interviewer_id
      person_id
      follow_up_notes
      deferred_to
      status
      notes
      created_at
      updated_at
      person {
        ...PersonFragment
      }
      interviewer {
        auth_id
        email
        name
        id
        roles {
          id
          name
        }
      }
    }
  }
  ${PersonFragmentFragmentDoc}
`;

/**
 * __useGetInterviewQuery__
 *
 * To run a query within a React component, call `useGetInterviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInterviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInterviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInterviewQuery(
  baseOptions: Apollo.QueryHookOptions<GetInterviewQuery, GetInterviewQueryVariables> &
    ({ variables: GetInterviewQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInterviewQuery, GetInterviewQueryVariables>(
    GetInterviewDocument,
    options,
  );
}
export function useGetInterviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInterviewQuery, GetInterviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInterviewQuery, GetInterviewQueryVariables>(
    GetInterviewDocument,
    options,
  );
}
export function useGetInterviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetInterviewQuery, GetInterviewQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetInterviewQuery, GetInterviewQueryVariables>(
    GetInterviewDocument,
    options,
  );
}
export type GetInterviewQueryHookResult = ReturnType<typeof useGetInterviewQuery>;
export type GetInterviewLazyQueryHookResult = ReturnType<typeof useGetInterviewLazyQuery>;
export type GetInterviewSuspenseQueryHookResult = ReturnType<typeof useGetInterviewSuspenseQuery>;
export type GetInterviewQueryResult = Apollo.QueryResult<
  GetInterviewQuery,
  GetInterviewQueryVariables
>;
export const GetInterviewsDocument = gql`
  query GetInterviews(
    $filters: [FilterInput!]!
    $pagination: PaginationInput
    $sort: SortingInput
  ) {
    getInterviews(filters: $filters, pagination: $pagination, sort: $sort) {
      interviews {
        id
        date
        deferred_to
        interviewer_id
        person_id
        follow_up_notes
        deferred_to
        status
        notes
        created_at
        updated_at
        person {
          ...PersonFragment
        }
        interviewer {
          auth_id
          email
          name
          id
          roles {
            id
            name
          }
        }
      }
      totalRecords
    }
  }
  ${PersonFragmentFragmentDoc}
`;

/**
 * __useGetInterviewsQuery__
 *
 * To run a query within a React component, call `useGetInterviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInterviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInterviewsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetInterviewsQuery(
  baseOptions: Apollo.QueryHookOptions<GetInterviewsQuery, GetInterviewsQueryVariables> &
    ({ variables: GetInterviewsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInterviewsQuery, GetInterviewsQueryVariables>(
    GetInterviewsDocument,
    options,
  );
}
export function useGetInterviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInterviewsQuery, GetInterviewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInterviewsQuery, GetInterviewsQueryVariables>(
    GetInterviewsDocument,
    options,
  );
}
export function useGetInterviewsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetInterviewsQuery, GetInterviewsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetInterviewsQuery, GetInterviewsQueryVariables>(
    GetInterviewsDocument,
    options,
  );
}
export type GetInterviewsQueryHookResult = ReturnType<typeof useGetInterviewsQuery>;
export type GetInterviewsLazyQueryHookResult = ReturnType<typeof useGetInterviewsLazyQuery>;
export type GetInterviewsSuspenseQueryHookResult = ReturnType<typeof useGetInterviewsSuspenseQuery>;
export type GetInterviewsQueryResult = Apollo.QueryResult<
  GetInterviewsQuery,
  GetInterviewsQueryVariables
>;
export const PatchInterviewDocument = gql`
  mutation PatchInterview($id: String!, $payload: PatchInterview!) {
    patchInterview(id: $id, payload: $payload) {
      id
      date
      deferred_to
      interviewer_id
      person_id
      follow_up_notes
      deferred_to
      status
      notes
      created_at
      updated_at
    }
  }
`;
export type PatchInterviewMutationFn = Apollo.MutationFunction<
  PatchInterviewMutation,
  PatchInterviewMutationVariables
>;

/**
 * __usePatchInterviewMutation__
 *
 * To run a mutation, you first call `usePatchInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchInterviewMutation, { data, loading, error }] = usePatchInterviewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function usePatchInterviewMutation(
  baseOptions?: Apollo.MutationHookOptions<PatchInterviewMutation, PatchInterviewMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PatchInterviewMutation, PatchInterviewMutationVariables>(
    PatchInterviewDocument,
    options,
  );
}
export type PatchInterviewMutationHookResult = ReturnType<typeof usePatchInterviewMutation>;
export type PatchInterviewMutationResult = Apollo.MutationResult<PatchInterviewMutation>;
export type PatchInterviewMutationOptions = Apollo.BaseMutationOptions<
  PatchInterviewMutation,
  PatchInterviewMutationVariables
>;
export const CreateKanbanCardDocument = gql`
  mutation CreateKanbanCard($payload: CreateKanbanCardInput!) {
    createKanbanCards(payload: $payload) {
      id
    }
  }
`;
export type CreateKanbanCardMutationFn = Apollo.MutationFunction<
  CreateKanbanCardMutation,
  CreateKanbanCardMutationVariables
>;

/**
 * __useCreateKanbanCardMutation__
 *
 * To run a mutation, you first call `useCreateKanbanCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKanbanCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKanbanCardMutation, { data, loading, error }] = useCreateKanbanCardMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateKanbanCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateKanbanCardMutation,
    CreateKanbanCardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateKanbanCardMutation, CreateKanbanCardMutationVariables>(
    CreateKanbanCardDocument,
    options,
  );
}
export type CreateKanbanCardMutationHookResult = ReturnType<typeof useCreateKanbanCardMutation>;
export type CreateKanbanCardMutationResult = Apollo.MutationResult<CreateKanbanCardMutation>;
export type CreateKanbanCardMutationOptions = Apollo.BaseMutationOptions<
  CreateKanbanCardMutation,
  CreateKanbanCardMutationVariables
>;
export const GetFollowupKanbanCardsDocument = gql`
  query GetFollowupKanbanCards($filters: FollowupKanbanCardInputs!) {
    getFollowupKanbanCards(filters: $filters) {
      id
      status
      created_at
      updated_at
      interview {
        id
        follow_up_notes
        notes
        date
        person {
          id
          first_name
          last_name
          city
          primary_email
          phone_number
          status
        }
        interviewer {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetFollowupKanbanCardsQuery__
 *
 * To run a query within a React component, call `useGetFollowupKanbanCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowupKanbanCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowupKanbanCardsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetFollowupKanbanCardsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFollowupKanbanCardsQuery,
    GetFollowupKanbanCardsQueryVariables
  > &
    ({ variables: GetFollowupKanbanCardsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFollowupKanbanCardsQuery, GetFollowupKanbanCardsQueryVariables>(
    GetFollowupKanbanCardsDocument,
    options,
  );
}
export function useGetFollowupKanbanCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFollowupKanbanCardsQuery,
    GetFollowupKanbanCardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFollowupKanbanCardsQuery, GetFollowupKanbanCardsQueryVariables>(
    GetFollowupKanbanCardsDocument,
    options,
  );
}
export function useGetFollowupKanbanCardsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetFollowupKanbanCardsQuery,
        GetFollowupKanbanCardsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFollowupKanbanCardsQuery, GetFollowupKanbanCardsQueryVariables>(
    GetFollowupKanbanCardsDocument,
    options,
  );
}
export type GetFollowupKanbanCardsQueryHookResult = ReturnType<
  typeof useGetFollowupKanbanCardsQuery
>;
export type GetFollowupKanbanCardsLazyQueryHookResult = ReturnType<
  typeof useGetFollowupKanbanCardsLazyQuery
>;
export type GetFollowupKanbanCardsSuspenseQueryHookResult = ReturnType<
  typeof useGetFollowupKanbanCardsSuspenseQuery
>;
export type GetFollowupKanbanCardsQueryResult = Apollo.QueryResult<
  GetFollowupKanbanCardsQuery,
  GetFollowupKanbanCardsQueryVariables
>;
export const PatchFollowupKanbanCardDocument = gql`
  mutation PatchFollowupKanbanCard($id: String!, $payload: PatchFollowupKanbanCardInput!) {
    patchFollowupKanbanCards(id: $id, payload: $payload) {
      id
      status
      interview {
        follow_up_notes
        notes
      }
    }
  }
`;
export type PatchFollowupKanbanCardMutationFn = Apollo.MutationFunction<
  PatchFollowupKanbanCardMutation,
  PatchFollowupKanbanCardMutationVariables
>;

/**
 * __usePatchFollowupKanbanCardMutation__
 *
 * To run a mutation, you first call `usePatchFollowupKanbanCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchFollowupKanbanCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchFollowupKanbanCardMutation, { data, loading, error }] = usePatchFollowupKanbanCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function usePatchFollowupKanbanCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PatchFollowupKanbanCardMutation,
    PatchFollowupKanbanCardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PatchFollowupKanbanCardMutation,
    PatchFollowupKanbanCardMutationVariables
  >(PatchFollowupKanbanCardDocument, options);
}
export type PatchFollowupKanbanCardMutationHookResult = ReturnType<
  typeof usePatchFollowupKanbanCardMutation
>;
export type PatchFollowupKanbanCardMutationResult =
  Apollo.MutationResult<PatchFollowupKanbanCardMutation>;
export type PatchFollowupKanbanCardMutationOptions = Apollo.BaseMutationOptions<
  PatchFollowupKanbanCardMutation,
  PatchFollowupKanbanCardMutationVariables
>;
export const GetPersonDocument = gql`
  query GetPerson($id: ID!) {
    getPerson(id: $id) {
      ...PersonFragment
      dinners {
        ...PersonDinnerFragment
      }
      experiences {
        ...PersonExperienceFragment
      }
      account_management {
        account_manager_id
        account_manager_name
        notes
      }
      events {
        created_at
        person_id
        type
        dinner_id
        metadata
      }
    }
  }
  ${PersonFragmentFragmentDoc}
  ${PersonDinnerFragmentFragmentDoc}
  ${PersonExperienceFragmentFragmentDoc}
`;

/**
 * __useGetPersonQuery__
 *
 * To run a query within a React component, call `useGetPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPersonQuery(
  baseOptions: Apollo.QueryHookOptions<GetPersonQuery, GetPersonQueryVariables> &
    ({ variables: GetPersonQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPersonQuery, GetPersonQueryVariables>(GetPersonDocument, options);
}
export function useGetPersonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPersonQuery, GetPersonQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPersonQuery, GetPersonQueryVariables>(GetPersonDocument, options);
}
export function useGetPersonSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetPersonQuery, GetPersonQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPersonQuery, GetPersonQueryVariables>(
    GetPersonDocument,
    options,
  );
}
export type GetPersonQueryHookResult = ReturnType<typeof useGetPersonQuery>;
export type GetPersonLazyQueryHookResult = ReturnType<typeof useGetPersonLazyQuery>;
export type GetPersonSuspenseQueryHookResult = ReturnType<typeof useGetPersonSuspenseQuery>;
export type GetPersonQueryResult = Apollo.QueryResult<GetPersonQuery, GetPersonQueryVariables>;
export const GetPersonAvailabilityDocument = gql`
  query GetPersonAvailability($personId: String!) {
    getPersonAvailability(personId: $personId) {
      id
      city
      date
      person_id
      created_at
      updated_at
    }
  }
`;

/**
 * __useGetPersonAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetPersonAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonAvailabilityQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetPersonAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPersonAvailabilityQuery,
    GetPersonAvailabilityQueryVariables
  > &
    ({ variables: GetPersonAvailabilityQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPersonAvailabilityQuery, GetPersonAvailabilityQueryVariables>(
    GetPersonAvailabilityDocument,
    options,
  );
}
export function useGetPersonAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPersonAvailabilityQuery,
    GetPersonAvailabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPersonAvailabilityQuery, GetPersonAvailabilityQueryVariables>(
    GetPersonAvailabilityDocument,
    options,
  );
}
export function useGetPersonAvailabilitySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPersonAvailabilityQuery,
        GetPersonAvailabilityQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPersonAvailabilityQuery, GetPersonAvailabilityQueryVariables>(
    GetPersonAvailabilityDocument,
    options,
  );
}
export type GetPersonAvailabilityQueryHookResult = ReturnType<typeof useGetPersonAvailabilityQuery>;
export type GetPersonAvailabilityLazyQueryHookResult = ReturnType<
  typeof useGetPersonAvailabilityLazyQuery
>;
export type GetPersonAvailabilitySuspenseQueryHookResult = ReturnType<
  typeof useGetPersonAvailabilitySuspenseQuery
>;
export type GetPersonAvailabilityQueryResult = Apollo.QueryResult<
  GetPersonAvailabilityQuery,
  GetPersonAvailabilityQueryVariables
>;
export const GetPersonDinnersDocument = gql`
  query GetPersonDinners($personId: String!) {
    getPersonDinners(personId: $personId) {
      id
      person_id
      matched_dinner_id
      status
      stripe_payment_intent_id
      dinner {
        id
        date
        description
        cost_per_seat
        price_per_seat
        status
        booking_url
        codename
        type
      }
      location {
        city
        name
        city
        contact_email
        contact_name
        contact_phone_number
        street_address
        arrival_instructions
        cuisine
        max_capacity
        min_capacity
        menu
        dress_code
      }
    }
  }
`;

/**
 * __useGetPersonDinnersQuery__
 *
 * To run a query within a React component, call `useGetPersonDinnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonDinnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonDinnersQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetPersonDinnersQuery(
  baseOptions: Apollo.QueryHookOptions<GetPersonDinnersQuery, GetPersonDinnersQueryVariables> &
    ({ variables: GetPersonDinnersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPersonDinnersQuery, GetPersonDinnersQueryVariables>(
    GetPersonDinnersDocument,
    options,
  );
}
export function useGetPersonDinnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPersonDinnersQuery, GetPersonDinnersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPersonDinnersQuery, GetPersonDinnersQueryVariables>(
    GetPersonDinnersDocument,
    options,
  );
}
export function useGetPersonDinnersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetPersonDinnersQuery, GetPersonDinnersQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPersonDinnersQuery, GetPersonDinnersQueryVariables>(
    GetPersonDinnersDocument,
    options,
  );
}
export type GetPersonDinnersQueryHookResult = ReturnType<typeof useGetPersonDinnersQuery>;
export type GetPersonDinnersLazyQueryHookResult = ReturnType<typeof useGetPersonDinnersLazyQuery>;
export type GetPersonDinnersSuspenseQueryHookResult = ReturnType<
  typeof useGetPersonDinnersSuspenseQuery
>;
export type GetPersonDinnersQueryResult = Apollo.QueryResult<
  GetPersonDinnersQuery,
  GetPersonDinnersQueryVariables
>;
export const GetPersonExperiencesDocument = gql`
  query GetPersonExperiences($personId: String!) {
    getPersonExperiences(personId: $personId) {
      id
      person_id
      experience_id
      ticket_count
      created_at
      transaction_id
      transaction {
        id
        status
        stripe_payment_intent_id
        description
      }
      experience {
        id
        title
        experience_type
        date
      }
      location {
        id
        name
        street_address
        city
        cuisine
      }
      feedback {
        id
        feedback
        overall_rating
      }
    }
  }
`;

/**
 * __useGetPersonExperiencesQuery__
 *
 * To run a query within a React component, call `useGetPersonExperiencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonExperiencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonExperiencesQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetPersonExperiencesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPersonExperiencesQuery,
    GetPersonExperiencesQueryVariables
  > &
    ({ variables: GetPersonExperiencesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPersonExperiencesQuery, GetPersonExperiencesQueryVariables>(
    GetPersonExperiencesDocument,
    options,
  );
}
export function useGetPersonExperiencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPersonExperiencesQuery,
    GetPersonExperiencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPersonExperiencesQuery, GetPersonExperiencesQueryVariables>(
    GetPersonExperiencesDocument,
    options,
  );
}
export function useGetPersonExperiencesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPersonExperiencesQuery,
        GetPersonExperiencesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPersonExperiencesQuery, GetPersonExperiencesQueryVariables>(
    GetPersonExperiencesDocument,
    options,
  );
}
export type GetPersonExperiencesQueryHookResult = ReturnType<typeof useGetPersonExperiencesQuery>;
export type GetPersonExperiencesLazyQueryHookResult = ReturnType<
  typeof useGetPersonExperiencesLazyQuery
>;
export type GetPersonExperiencesSuspenseQueryHookResult = ReturnType<
  typeof useGetPersonExperiencesSuspenseQuery
>;
export type GetPersonExperiencesQueryResult = Apollo.QueryResult<
  GetPersonExperiencesQuery,
  GetPersonExperiencesQueryVariables
>;
export const GetPersonInterviewDocument = gql`
  query GetPersonInterview($personId: String!) {
    getPersonInterview(personId: $personId) {
      id
      date
      deferred_to
      interviewer_id
      person_id
      follow_up_notes
      deferred_to
      status
      notes
      created_at
      updated_at
      person {
        ...PersonFragment
      }
      interviewer {
        name
      }
    }
  }
  ${PersonFragmentFragmentDoc}
`;

/**
 * __useGetPersonInterviewQuery__
 *
 * To run a query within a React component, call `useGetPersonInterviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonInterviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonInterviewQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetPersonInterviewQuery(
  baseOptions: Apollo.QueryHookOptions<GetPersonInterviewQuery, GetPersonInterviewQueryVariables> &
    ({ variables: GetPersonInterviewQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPersonInterviewQuery, GetPersonInterviewQueryVariables>(
    GetPersonInterviewDocument,
    options,
  );
}
export function useGetPersonInterviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPersonInterviewQuery,
    GetPersonInterviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPersonInterviewQuery, GetPersonInterviewQueryVariables>(
    GetPersonInterviewDocument,
    options,
  );
}
export function useGetPersonInterviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetPersonInterviewQuery, GetPersonInterviewQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPersonInterviewQuery, GetPersonInterviewQueryVariables>(
    GetPersonInterviewDocument,
    options,
  );
}
export type GetPersonInterviewQueryHookResult = ReturnType<typeof useGetPersonInterviewQuery>;
export type GetPersonInterviewLazyQueryHookResult = ReturnType<
  typeof useGetPersonInterviewLazyQuery
>;
export type GetPersonInterviewSuspenseQueryHookResult = ReturnType<
  typeof useGetPersonInterviewSuspenseQuery
>;
export type GetPersonInterviewQueryResult = Apollo.QueryResult<
  GetPersonInterviewQuery,
  GetPersonInterviewQueryVariables
>;
export const GetPersonsDocument = gql`
  query GetPersons($filters: [FilterInput!]!, $pagination: PaginationInput, $sort: SortingInput) {
    getPersons(filters: $filters, pagination: $pagination, sort: $sort) {
      totalRecords
      persons {
        ...PersonFragment
      }
    }
  }
  ${PersonFragmentFragmentDoc}
`;

/**
 * __useGetPersonsQuery__
 *
 * To run a query within a React component, call `useGetPersonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetPersonsQuery(
  baseOptions: Apollo.QueryHookOptions<GetPersonsQuery, GetPersonsQueryVariables> &
    ({ variables: GetPersonsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPersonsQuery, GetPersonsQueryVariables>(GetPersonsDocument, options);
}
export function useGetPersonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPersonsQuery, GetPersonsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPersonsQuery, GetPersonsQueryVariables>(
    GetPersonsDocument,
    options,
  );
}
export function useGetPersonsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetPersonsQuery, GetPersonsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPersonsQuery, GetPersonsQueryVariables>(
    GetPersonsDocument,
    options,
  );
}
export type GetPersonsQueryHookResult = ReturnType<typeof useGetPersonsQuery>;
export type GetPersonsLazyQueryHookResult = ReturnType<typeof useGetPersonsLazyQuery>;
export type GetPersonsSuspenseQueryHookResult = ReturnType<typeof useGetPersonsSuspenseQuery>;
export type GetPersonsQueryResult = Apollo.QueryResult<GetPersonsQuery, GetPersonsQueryVariables>;
export const PatchPersonDocument = gql`
  mutation PatchPerson($id: String!, $payload: PatchPerson!) {
    patchPerson(id: $id, payload: $payload) {
      ...PersonFragment
    }
  }
  ${PersonFragmentFragmentDoc}
`;
export type PatchPersonMutationFn = Apollo.MutationFunction<
  PatchPersonMutation,
  PatchPersonMutationVariables
>;

/**
 * __usePatchPersonMutation__
 *
 * To run a mutation, you first call `usePatchPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchPersonMutation, { data, loading, error }] = usePatchPersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function usePatchPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<PatchPersonMutation, PatchPersonMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PatchPersonMutation, PatchPersonMutationVariables>(
    PatchPersonDocument,
    options,
  );
}
export type PatchPersonMutationHookResult = ReturnType<typeof usePatchPersonMutation>;
export type PatchPersonMutationResult = Apollo.MutationResult<PatchPersonMutation>;
export type PatchPersonMutationOptions = Apollo.BaseMutationOptions<
  PatchPersonMutation,
  PatchPersonMutationVariables
>;
export const UpdateMembershipStatusDocument = gql`
  mutation UpdateMembershipStatus($id: String!, $status: String!, $payload: JSON!) {
    updateMembershipStatus(id: $id, status: $status, payload: $payload)
  }
`;
export type UpdateMembershipStatusMutationFn = Apollo.MutationFunction<
  UpdateMembershipStatusMutation,
  UpdateMembershipStatusMutationVariables
>;

/**
 * __useUpdateMembershipStatusMutation__
 *
 * To run a mutation, you first call `useUpdateMembershipStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembershipStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembershipStatusMutation, { data, loading, error }] = useUpdateMembershipStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateMembershipStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMembershipStatusMutation,
    UpdateMembershipStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMembershipStatusMutation,
    UpdateMembershipStatusMutationVariables
  >(UpdateMembershipStatusDocument, options);
}
export type UpdateMembershipStatusMutationHookResult = ReturnType<
  typeof useUpdateMembershipStatusMutation
>;
export type UpdateMembershipStatusMutationResult =
  Apollo.MutationResult<UpdateMembershipStatusMutation>;
export type UpdateMembershipStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateMembershipStatusMutation,
  UpdateMembershipStatusMutationVariables
>;
export const GetRolesDocument = gql`
  query GetRoles {
    getRoles {
      id
      name
    }
  }
`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export function useGetRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export function useGetRolesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesSuspenseQueryHookResult = ReturnType<typeof useGetRolesSuspenseQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const GetStatsDocument = gql`
  query GetStats($filters: InterviewerStatsInput!) {
    getInterviewStats(filters: $filters) {
      interviewerId
      interviewerName
      totalInterviews
      totalCompletedInterviews
      interviewWins
      interviewLoss
      closeRate
      subscriptionTypes
    }
  }
`;

/**
 * __useGetStatsQuery__
 *
 * To run a query within a React component, call `useGetStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetStatsQuery(
  baseOptions: Apollo.QueryHookOptions<GetStatsQuery, GetStatsQueryVariables> &
    ({ variables: GetStatsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStatsQuery, GetStatsQueryVariables>(GetStatsDocument, options);
}
export function useGetStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStatsQuery, GetStatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStatsQuery, GetStatsQueryVariables>(GetStatsDocument, options);
}
export function useGetStatsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetStatsQuery, GetStatsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetStatsQuery, GetStatsQueryVariables>(GetStatsDocument, options);
}
export type GetStatsQueryHookResult = ReturnType<typeof useGetStatsQuery>;
export type GetStatsLazyQueryHookResult = ReturnType<typeof useGetStatsLazyQuery>;
export type GetStatsSuspenseQueryHookResult = ReturnType<typeof useGetStatsSuspenseQuery>;
export type GetStatsQueryResult = Apollo.QueryResult<GetStatsQuery, GetStatsQueryVariables>;
export const CreateUserDocument = gql`
  mutation CreateUser($input: UserCreateInput!) {
    createUser(input: $input) {
      id
      auth_id
      email
      name
      roles {
        id
        name
      }
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options,
  );
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options,
  );
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const GetLoggedInUserDocument = gql`
  query GetLoggedInUser {
    getLoggedInUser {
      auth_id
      email
      name
      id
      roles {
        id
        name
      }
    }
  }
`;

/**
 * __useGetLoggedInUserQuery__
 *
 * To run a query within a React component, call `useGetLoggedInUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoggedInUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoggedInUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoggedInUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>(
    GetLoggedInUserDocument,
    options,
  );
}
export function useGetLoggedInUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>(
    GetLoggedInUserDocument,
    options,
  );
}
export function useGetLoggedInUserSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>(
    GetLoggedInUserDocument,
    options,
  );
}
export type GetLoggedInUserQueryHookResult = ReturnType<typeof useGetLoggedInUserQuery>;
export type GetLoggedInUserLazyQueryHookResult = ReturnType<typeof useGetLoggedInUserLazyQuery>;
export type GetLoggedInUserSuspenseQueryHookResult = ReturnType<
  typeof useGetLoggedInUserSuspenseQuery
>;
export type GetLoggedInUserQueryResult = Apollo.QueryResult<
  GetLoggedInUserQuery,
  GetLoggedInUserQueryVariables
>;
export const GetUsersDocument = gql`
  query GetUsers {
    getUsers {
      id
      email
      name
      roles {
        id
        name
      }
    }
  }
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersSuspenseQueryHookResult = ReturnType<typeof useGetUsersSuspenseQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($id: ID!, $input: UserUpdateInput!) {
    updateUser(id: $id, input: $input) {
      id
      auth_id
      email
      name
      roles {
        id
        name
      }
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const CreateUserLogDocument = gql`
  mutation CreateUserLog($input: CreateUserLogInput!) {
    createUserLog(input: $input) {
      id
      person_id
      user_id
      created_at
      updated_at
      content
    }
  }
`;
export type CreateUserLogMutationFn = Apollo.MutationFunction<
  CreateUserLogMutation,
  CreateUserLogMutationVariables
>;

/**
 * __useCreateUserLogMutation__
 *
 * To run a mutation, you first call `useCreateUserLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserLogMutation, { data, loading, error }] = useCreateUserLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserLogMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserLogMutation, CreateUserLogMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserLogMutation, CreateUserLogMutationVariables>(
    CreateUserLogDocument,
    options,
  );
}
export type CreateUserLogMutationHookResult = ReturnType<typeof useCreateUserLogMutation>;
export type CreateUserLogMutationResult = Apollo.MutationResult<CreateUserLogMutation>;
export type CreateUserLogMutationOptions = Apollo.BaseMutationOptions<
  CreateUserLogMutation,
  CreateUserLogMutationVariables
>;
export const GetUserLogsDocument = gql`
  query GetUserLogs($type: String, $personId: String!, $pagination: PaginationInput!) {
    getUserLogs(personId: $personId, pagination: $pagination, type: $type) {
      id
      person_id
      user_id
      created_at
      updated_at
      content
      user {
        id
        name
      }
    }
  }
`;

/**
 * __useGetUserLogsQuery__
 *
 * To run a query within a React component, call `useGetUserLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLogsQuery({
 *   variables: {
 *      type: // value for 'type'
 *      personId: // value for 'personId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetUserLogsQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserLogsQuery, GetUserLogsQueryVariables> &
    ({ variables: GetUserLogsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserLogsQuery, GetUserLogsQueryVariables>(GetUserLogsDocument, options);
}
export function useGetUserLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserLogsQuery, GetUserLogsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserLogsQuery, GetUserLogsQueryVariables>(
    GetUserLogsDocument,
    options,
  );
}
export function useGetUserLogsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetUserLogsQuery, GetUserLogsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUserLogsQuery, GetUserLogsQueryVariables>(
    GetUserLogsDocument,
    options,
  );
}
export type GetUserLogsQueryHookResult = ReturnType<typeof useGetUserLogsQuery>;
export type GetUserLogsLazyQueryHookResult = ReturnType<typeof useGetUserLogsLazyQuery>;
export type GetUserLogsSuspenseQueryHookResult = ReturnType<typeof useGetUserLogsSuspenseQuery>;
export type GetUserLogsQueryResult = Apollo.QueryResult<
  GetUserLogsQuery,
  GetUserLogsQueryVariables
>;
