import { InformationCircleIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';

import { GetPersonExperiencesQuery, useGetPersonExperiencesQuery } from '@/__generated__/graphql';
import { SimpleTable, SimpleTableColumn } from '@/components/Tables/SimpleTable';
import { SectionWithHeader } from '@/components/sections/SectionWithHeader';

type Props = {
  personId: string;
};

export const PersonExperiences = ({ personId }: Props) => {
  const { data, loading } = useGetPersonExperiencesQuery({ variables: { personId } });
  const experiences = data?.getPersonExperiences;
  const experienceColumns: SimpleTableColumn<
    GetPersonExperiencesQuery['getPersonExperiences'][0]
  >[] = [
    {
      accessorKey: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      accessorKey: 'name',
      headerName: 'Name',
      accessorFunc: (row) => {
        return row.experience?.title;
      },
      className: 'whitespace-nowrap max-w-[200px] overflow-hidden text-ellipsis',
      enableTooltip: true,
    },
    {
      accessorKey: 'type',
      headerName: 'Type',
      accessorFunc: (row) => {
        return row.experience?.experience_type;
      },
    },
    {
      accessorKey: 'locationName',
      headerName: 'Location Name',
      accessorFunc: (row) => {
        return row.location?.name;
      },
      enableTooltip: true,
    },
    {
      accessorKey: 'locationCity',
      headerName: 'Location City',
      accessorFunc: (row) => {
        return row.location?.city;
      },
    },
    {
      accessorKey: 'ticket_count',
      headerName: '# Tickets',
    },
    {
      accessorKey: 'date',
      headerName: 'Date',
      accessorFunc: (row) => {
        return row?.experience?.date ? dayjs(row.experience.date).format('MMM D, YYYY') : '';
      },
      className: 'whitespace-nowrap',
    },
    {
      accessorKey: 'status',
      headerName: 'Payment Status',
      accessorFunc: (row) => {
        return row.transaction?.status;
      },
    },
    {
      accessorKey: 'feedbackRating',
      headerName: 'Feedback Rating',
      accessorFunc: (row) => {
        return (
          <span className={'flex items-center gap-1'}>
            {row.feedback?.overall_rating ? `${row.feedback?.overall_rating}/5` : null}
            {row?.feedback?.feedback ? (
              <InformationCircleIcon title={row.feedback.feedback} className={'h-4 w-4'} />
            ) : null}
          </span>
        );
      },
    },
  ];

  return (
    <div>
      <SectionWithHeader title={'Experiences'}>
        <SimpleTable
          data={[...(experiences || [])]?.sort((a, b) => {
            const dateA: any = new Date(a?.experience?.date || -1);
            const dateB: any = new Date(b?.experience?.date || -1);
            return dateB - dateA;
          })}
          columns={experienceColumns}
          loading={loading}
        />
      </SectionWithHeader>
    </div>
  );
};
