import { TrashIcon } from '@heroicons/react/24/outline';
import { startCase } from 'lodash-es';
import { useEffect, useState } from 'react';

import { FilterConditionOperatorEnum } from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import Input from '@/components/basic/inputs/Input';
import Select from '@/components/basic/inputs/Select';

export type IFilterCondition = {
  field: string;
  operator: FilterConditionOperatorEnum;
  value: string;
};

export type IFilter = {
  field: string;
  values?: string[]; // If values, then select input
  type?: 'string' | 'number' | 'date'; // Input type
  operations?: FilterConditionOperatorEnum[];
};

type IFilterData = {
  fields: string[];
  values: { [key: string]: string[] };
  fieldTypes: { [key: string]: string };
  fieldOperators: { [key: string]: Set<FilterConditionOperatorEnum> };
};

type Props = {
  filterConfigs: IFilter[];
  filters: IFilterCondition[];
  setFilters: (conditions: IFilterCondition[]) => void;
};

export const FilterPanelContent = ({ filters, filterConfigs, setFilters }: Props) => {
  const [filterData, setFilterData] = useState<IFilterData>({
    fields: [],
    values: {},
    fieldTypes: {},
    fieldOperators: {},
  });

  const addCondition = () => {
    setFilters([
      ...filters,
      { field: '', operator: FilterConditionOperatorEnum.Equals, value: '' },
    ]);
  };

  const updateCondition = (
    index: number,
    key: keyof IFilterCondition,
    value: FilterConditionOperatorEnum,
  ) => {
    const updatedConditions = [...filters];
    updatedConditions[index][key] = value;

    if (key === 'field') {
      updatedConditions[index]['operator'] = '' as any;
    }

    setFilters(updatedConditions);
  };

  const removeCondition = (index: number) => {
    setFilters(filters.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const tempFilters: IFilterData = {
      fields: [],
      values: {},
      fieldTypes: {},
      fieldOperators: {},
    };

    filterConfigs?.forEach((filter) => {
      tempFilters.fields.push(filter.field);
      tempFilters.fieldTypes[filter.field] = filter.type || 'text';

      if (filter.operations?.length) {
        tempFilters.fieldOperators[filter.field] = new Set(filter.operations);
      }

      if (filter.values) {
        tempFilters.values[filter.field] = filter.values;
      }
    });

    setFilterData(tempFilters);
  }, [filterConfigs]);

  return (
    <div>
      {filters.map((condition, index) => {
        return (
          <div key={index} className="mb-1 flex items-center rounded-md bg-base-100 px-3">
            {/* Field Selector */}
            <Select
              value={condition.field}
              onChange={(e) =>
                updateCondition(index, 'field', e.target.value as FilterConditionOperatorEnum)
              }
            >
              <option value="">Select Field</option>
              {filterData.fields.map((field) => (
                <option key={field} value={field}>
                  {startCase(field.toLowerCase())}
                </option>
              ))}
            </Select>

            {/* Operator Selector */}
            <Select
              value={condition.operator}
              onChange={(e) =>
                updateCondition(index, 'operator', e.target.value as FilterConditionOperatorEnum)
              }
            >
              <option disabled={true} value={''}>
                Select Operator
              </option>
              {Object.values(FilterConditionOperatorEnum).map((operator) => {
                if (
                  filterData.fieldOperators[condition.field]?.size &&
                  !filterData.fieldOperators[condition.field].has(operator)
                ) {
                  return null;
                }

                return (
                  <option key={operator} value={operator}>
                    {startCase(operator.toLowerCase())}
                  </option>
                );
              })}
            </Select>

            {/* Value Selector */}
            {[FilterConditionOperatorEnum.Equals, FilterConditionOperatorEnum.NotEquals].includes(
              condition.operator,
            ) && filterData.values?.[condition.field]?.length ? (
              <Select
                value={condition.value}
                onChange={(e) =>
                  updateCondition(index, 'value', e.target.value as FilterConditionOperatorEnum)
                }
                disabled={!condition.field}
              >
                <option value="">Select Value</option>
                {condition.field &&
                  filterData.values?.[condition.field]?.map((val) => (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  ))}
              </Select>
            ) : (
              <Input
                inputSize={'sm'}
                type={filterData.fieldTypes?.[condition.field]}
                value={condition.value}
                onChange={(e) =>
                  updateCondition(index, 'value', e.target.value as FilterConditionOperatorEnum)
                }
              />
            )}

            {/* Remove Button */}
            <Button
              size={'sm'}
              shape={'square'}
              color={'error'}
              className={'bg-error p-1 text-white'}
              variant={'ghost'}
              onClick={() => removeCondition(index)}
            >
              <TrashIcon className={'w-6'} />
            </Button>
          </div>
        );
      })}

      <div className={'mt-5 flex items-center gap-2'}>
        {!filters.length ? (
          <p className={'min-w-[200px] p-2 text-gray-700'}>No filters applied</p>
        ) : null}
        <span className={'flex-1'} />
        <Button size={'sm'} onClick={addCondition}>
          Add Condition
        </Button>
      </div>
    </div>
  );
};
