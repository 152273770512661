import { XMarkIcon } from '@heroicons/react/24/outline';

import {
  FilterPanelContent,
  IFilter,
  IFilterCondition,
} from '@/components/FilterPanel/FilterPanelContent';
import Popover from '@/components/Popover';
import Button from '@/components/basic/buttons/Button';

type Props = {
  filterKeyValues: IFilter[];
  filters: IFilterCondition[];
  setFilters: (conditions: IFilterCondition[]) => void;
};

export const FilterPanel = ({ filters, setFilters, filterKeyValues }: Props) => {
  const completeFilters = filters.filter(
    (filter) => filter.field && filter.value && filter.operator,
  );
  return (
    <Popover
      horizontal={'left'}
      triggerAction={'click'}
      content={
        <FilterPanelContent
          filterConfigs={filterKeyValues}
          filters={filters}
          setFilters={setFilters}
        />
      }
      trigger={
        <div className={'flex items-center'}>
          <Button color={'neutral'} variant={'link'} className={'pr-0'}>
            Filters {completeFilters.length ? `(${completeFilters.length})` : ''}
          </Button>
          {completeFilters.length ? (
            <Button onClick={() => setFilters([])} color={'ghost'} size={'xs'} className={'p-0'}>
              <XMarkIcon className={'w-4 text-error'} />
            </Button>
          ) : null}
        </div>
      }
    />
  );
};
