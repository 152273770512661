import dayjs from 'dayjs';

import { useGetPersonInterviewQuery } from '@/__generated__/graphql';
import { DetailTableSection } from '@/components/sections/DetailTableSection';
import { InterviewFormNameToStars } from '@/pages/Interviews/InterviewsQueuePage/components/InterviewFormNameToStars';
import { toTitleCase } from '@/shared/utils/utils';

type Props = {
  personId: string;
};

export const PersonInterview = ({ personId }: Props) => {
  const { data, loading, error } = useGetPersonInterviewQuery({ variables: { personId } });
  const interview = data?.getPersonInterview;
  const person = interview?.person;

  const details = [
    {
      title: 'Name',
      value: interview?.interviewer?.name,
    },
    {
      title: 'Date',
      value: interview?.date ? dayjs(interview.date).format('MMM D, YYYY z') : '',
    },
    {
      title: 'Status',
      value: interview?.status,
    },
    ...(person
      ? Object.keys(person)
          .filter((key) => key.startsWith('score'))
          .map((key) => {
            const tempKey = key.replaceAll('score_', '');
            return {
              title: toTitleCase(tempKey).replaceAll('_', ' '),
              value: person[key]
                ? `${person[key]} out of ${InterviewFormNameToStars[tempKey]}` + ' Stars'
                : null,
            };
          })
      : []),
    {
      title: 'Interview Notes',
      value: (
        <div
          className={'max-h-[200px] w-full overflow-auto p-1 text-xs'}
          dangerouslySetInnerHTML={{ __html: interview?.notes || 'No Notes' }}
        />
      ),
    },
  ];

  if (loading) {
    return <div className={'loading'} />;
  }

  return (
    <div>
      {error && <div className={'my-4 text-sm text-error'}>{error.message}</div>}

      {interview ? (
        <DetailTableSection title={'Interview'} details={details} />
      ) : (
        <div>
          <h3 className={'text-xl font-semibold'}>No Interview Found</h3>
          <p className={'mt-4 text-sm'}>
            This may happen if they joined before this CRM was launched <i>(Dec 2024)</i>
          </p>
        </div>
      )}
    </div>
  );
};
