import { RolesEnum } from '@/shared/enums';

export const pageRoles = {
  Dashboard: [],
  UserManagement: [
    RolesEnum.interviewerAdmin,
    RolesEnum.accountManagerAdmin,
    RolesEnum.customerSuccessAdmin,
  ],
  InterviewQueue: [
    RolesEnum.interviewerAdmin,
    RolesEnum.interviewerApprover,
    RolesEnum.interviewer,
  ],
  Interviews: [RolesEnum.interviewer, RolesEnum.interviewerAdmin, RolesEnum.interviewerApprover],
  FinishedInterviews: [RolesEnum.interviewerAdmin, RolesEnum.interviewerApprover],
  InterviewFollowUp: [
    RolesEnum.interviewerAdmin,
    RolesEnum.interviewerApprover,
    RolesEnum.interviewer,
  ],
  AccountManager: [RolesEnum.accountManager, RolesEnum.accountManagerAdmin],
  Experiences: [
    RolesEnum.accountManager,
    RolesEnum.accountManagerAdmin,
    RolesEnum.customerSuccess,
    RolesEnum.customerSuccessAdmin,
  ],
  Dinners: [
    RolesEnum.accountManager,
    RolesEnum.accountManagerAdmin,
    RolesEnum.customerSuccess,
    RolesEnum.customerSuccessAdmin,
  ],
  Person: [RolesEnum.customerSuccess, RolesEnum.customerSuccessAdmin],
};
