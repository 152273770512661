import dayjs from 'dayjs';

import { Person } from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import { DetailTableSection } from '@/components/sections/DetailTableSection';
import { copyToClipboard, formatPhoneNumber } from '@/shared/utils/utils';

type Props = {
  person: Person;
};

export const PersonBasicDetails = ({ person }: Props) => {
  const details = [
    { title: 'Full Name', value: `${person.first_name} ${person.last_name}` },
    { title: 'City', value: person.city },
    {
      title: 'Age Range',
      value: person.application_age_range,
    },
    {
      title: 'Email',
      value: person.primary_email,
    },
    {
      title: 'Phone Number',
      value: formatPhoneNumber(person.phone_number || ''),
    },
    {
      title: 'Status',
      value: person?.status,
    },
    {
      title: 'Membership Status',
      value: person?.membership_status,
    },
    {
      title: 'Joined Date',
      value: person?.membership_started_at
        ? dayjs(person.membership_started_at).format('MMM DD, YYYY')
        : '',
    },
    {
      title: 'Referred By',
      value: person.referred_by_person?.first_name
        ? `${person.referred_by_person?.first_name} ${person.referred_by_person?.last_name}`
        : person?.referred_by_text,
    },
    {
      title: 'Match After Date',
      value: person?.match_after_date ? dayjs(person.match_after_date).format('MMM DD, YYYY') : '',
    },
    {
      title: 'Value Expectation',
      value: person?.value_expectation,
    },
    {
      title: 'Credits',
      value: person?.credit_balance,
    },
    {
      title: 'ID',
      value: (
        <Button
          size={'xs'}
          variant={'link'}
          color={'secondary'}
          onClick={() => copyToClipboard(person?.id || '')}
        >
          Copy
        </Button>
      ),
    },
  ];

  return <DetailTableSection title={'Person'} details={details} />;
};
