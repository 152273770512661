import { XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';

import Button from '@/components/basic/buttons/Button';

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ModalBase: React.FC<ModalProps> = ({
  title,
  children,
  isOpen,
  onClose,
  className = '',
  ...props
}) => {
  if (!isOpen) return null;

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center ${className}`}
      role="dialog"
      {...props}
    >
      <div className="modal modal-open">
        <div className="modal-box overflow-visible">
          <div className="modal-header flex items-center justify-between">
            <h3 className="font-['Forum'] text-2xl font-bold">{title}</h3>
            <Button size={'xs'} color={'ghost'} shape={'circle'} onClick={onClose}>
              <XMarkIcon className={'w-4'} />
            </Button>
          </div>
          <div className="modal-body mt-4">{children}</div>
        </div>
        <div className="modal-backdrop" onClick={onClose} />
      </div>
    </div>
  );
};
