import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  title: string;
  children?: ReactNode;
  className?: HTMLDivElement['className'];
  action?: ReactNode;
}

export const SectionWithHeader = ({ children, title, className, action }: Props) => {
  return (
    <div className={twMerge('h-max w-full rounded-lg border border-gray-500 bg-white', className)}>
      <div className={'flex items-center gap-4 rounded-t-lg bg-neutral px-4 py-2'}>
        <h5 className={'text-lg font-bold tracking-wider text-white'}>{title}</h5>
        <span className={'flex-1'} />
        {action}
      </div>
      {children}
    </div>
  );
};
