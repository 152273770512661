interface TabProps {
  tabNames: string[];
  currentTab: number | string;
  onTabClick: (index: number | string) => void;
  returnIndex?: boolean;
}

export const TabsComponent = ({ tabNames, currentTab, onTabClick, returnIndex }: TabProps) => {
  return (
    <div role="tablist" className={'bg-off-white tabs tabs-lifted'}>
      {tabNames.map((tabName, index) => (
        <a
          key={tabName + index}
          role="tab"
          className={`tab ${currentTab === index && 'tab-active'}`}
          onClick={() => onTabClick(returnIndex ? index : tabName)}
        >
          {tabName}
        </a>
      ))}
    </div>
  );
};
