import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

import {
  FilterConditionOperatorEnum,
  Interview,
  useCreateInterviewMutation,
  useGetPersonsQuery,
  useGetUsersQuery,
  usePatchInterviewMutation,
} from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import Autocomplete from '@/components/basic/inputs/Autocomplete';
import Input from '@/components/basic/inputs/Input';
import Select from '@/components/basic/inputs/Select';
import { ModalBase } from '@/components/modals/ModalBase';
import { InterviewsStatusEnum, RolesEnum } from '@/shared/enums';
import { hasRoleAccess } from '@/shared/utils/rolesUtils';
import { toTitleCase } from '@/shared/utils/utils';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  interview?: Interview;
  interviewerId?: string;
};

type interviewForm = {
  id?: string;
  date: string;
  status: string;
  interviewer_id: string;
  person_id: string;
};

export const UpsertInterviewModal = ({ isOpen, onClose, interview, interviewerId = '' }: Props) => {
  const { data: userRes } = useGetUsersQuery();
  const interviewers =
    userRes?.getUsers.filter((u) =>
      hasRoleAccess(u.roles, [RolesEnum.interviewer, RolesEnum.interviewerAdmin]),
    ) || [];
  const [personSearch, setPersonSearch] = useState('');
  const [form, setForm] = useState<interviewForm>({
    date: '',
    status: '',
    interviewer_id: interviewerId,
    person_id: '',
  });
  const { data: personsRes, refetch: refetchPersons } = useGetPersonsQuery({
    variables: {
      filters: [],
      pagination: { limit: 10, offset: 0 },
    },
  });
  const persons = personsRes?.getPersons.persons || [];
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [createInterview, { loading: createLoading }] = useCreateInterviewMutation();
  const [patchInterview, { loading: patchLoading }] = usePatchInterviewMutation();
  const loading = createLoading || patchLoading;

  const handlePersonSelected = (person) => {
    if (person?.id === form.person_id) {
      return;
    }

    setForm({ ...form, person_id: person?.id });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setErrors({});
    if (!form.person_id) {
      setErrors({ person_id: 'Please select a person from list' });
      return;
    }

    try {
      if (form.id) {
        const { id, ...other } = form;
        await patchInterview({
          variables: {
            id,
            payload: {
              status: other.status,
              interviewer_id: other.interviewer_id,
              date: other.date,
            },
          },
        });
      } else {
        const interview = await createInterview({
          variables: {
            payload: {
              status: form.status,
              interviewer_id: form.interviewer_id,
              person_id: form.person_id,
              date: form.date,
            },
          },
        });

        setForm({ ...form, id: interview.data?.createInterview.id });
      }

      enqueueSnackbar('Created Interview', { variant: 'success' });
    } catch (error: any) {
      console.error(error?.message);
      enqueueSnackbar(error?.message || 'Failed', { variant: 'error' });
    }
  };

  const handleDateChange = (e: any) => {
    let newDate = dayjs(e.target.value); // create dayjs date from input
    if (form.date) {
      const oldDate = dayjs(form.date); // create dayjs date from form
      newDate = newDate.hour(oldDate.hour()).minute(oldDate.minute());
    }
    // keep the time from old date, and date from new date
    setForm({ ...form, date: newDate.toISOString() });
  };

  const handleTimeChange = (e: any) => {
    const [hours, minutes] = e.target.value.split(':').map(Number);
    const updatedDate = form.date ? dayjs(form.date) : dayjs();
    setForm({ ...form, date: updatedDate.hour(hours).minute(minutes).toISOString() });
  };

  useEffect(() => {
    refetchPersons({
      filters: [
        { field: 'search', operator: FilterConditionOperatorEnum.Contains, value: personSearch },
      ],
      pagination: { limit: 10, offset: 0 },
    });
  }, [personSearch]);

  useEffect(() => {
    if (interview) {
      setForm({
        id: interview.id,
        person_id: interview.person_id,
        date: interview.date,
        status: interview.status,
        interviewer_id: interview.interviewer_id,
      });
    } else {
      setForm({
        person_id: '',
        date: '',
        status: '',
        interviewer_id: interviewerId,
      });
    }
  }, [interview]);

  return (
    <ModalBase
      title={(form.id ? 'Update' : 'New') + ' Interview'}
      isOpen={isOpen}
      onClose={onClose}
      className={'overflow-visible'}
    >
      <form onSubmit={handleSubmit} className={'flex flex-col gap-2'}>
        <Select
          inputSize={'sm'}
          label={'Status'}
          value={form.status}
          onChange={(e) => setForm({ ...form, status: e.target.value })}
          required={true}
        >
          <option value={''} disabled={true}>
            Select Status
          </option>
          {Object.values(InterviewsStatusEnum).map((status) => {
            return (
              <option key={status} value={status}>
                {toTitleCase(status)}
              </option>
            );
          })}
        </Select>
        <Input
          inputSize={'sm'}
          label={'Interview Date'}
          value={form.date ? dayjs(form.date).format('YYYY-MM-DD') : ''}
          type={'date'}
          required={true}
          onChange={handleDateChange}
        />
        <Input
          inputSize={'sm'}
          label={'Interview Time'}
          value={form.date ? dayjs(form.date).format('HH:mm') : ''}
          type={'time'}
          required={true}
          onChange={handleTimeChange}
          hint={`Local Time: ${dayjs().format('z') || 'UTC'}`}
        />

        <Select
          inputSize={'sm'}
          label={'Interviewer'}
          value={form.interviewer_id}
          onChange={(e) => setForm({ ...form, interviewer_id: e.target.value })}
          required={true}
        >
          <option value={''} disabled={true}>
            Select Interviewer
          </option>
          {interviewers
            ?.sort((a, b) => {
              if (a.name > b.name) {
                return 1;
              } else if (a.name < b.name) {
                return -1;
              } else {
                return 0;
              }
            })
            ?.map((interviewer) => {
              return (
                <option key={interviewer.id} value={interviewer.id}>
                  {interviewer.name}
                </option>
              );
            })}
        </Select>

        {form.id ? (
          <div className={'p-1'}>
            <h4 className={'text-md'}>
              {interview?.person.first_name} {interview?.person.last_name}
            </h4>
            <p className={'text-xs text-gray-600'}>{interview?.person.primary_email}</p>
          </div>
        ) : (
          <Autocomplete
            required={true}
            label={'Select Person'}
            itemIdentifierKey={'id'}
            items={persons}
            itemToString={(person) => (person ? `${person.first_name} ${person.last_name}` : '')}
            renderItem={(person) => (
              <div className={'p-1'}>
                <h4 className={'text-md'}>
                  {person.first_name} {person.last_name}
                </h4>
                <p className={'text-xs text-gray-600'}>{person.primary_email}</p>
              </div>
            )}
            onInputValueChange={(input) => setPersonSearch(input)}
            onSelectedItemChange={handlePersonSelected}
            placeholder={''}
            hint={errors.person_id || ''}
            isErrorHint={true}
            initialInputValue={
              interview?.person
                ? `${interview.person.first_name} ${interview.person.last_name}`
                : ''
            }
          />
        )}
        <Button loading={loading} type={'submit'} size={'sm'}>
          Save
        </Button>
      </form>
    </ModalBase>
  );
};
