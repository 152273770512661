import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';

import { Person } from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import { DetailTableSection } from '@/components/sections/DetailTableSection';
import { getStripeUrl } from '@/shared/utils/utils';

type Props = {
  person: Person;
};

export const PersonalDetails = ({ person }: Props) => {
  const details = [
    {
      title: 'Date of Birth',
      value: person?.date_of_birth
        ? `${dayjs(person.date_of_birth).format('MMM DD, YYYY')} (${dayjs(person.date_of_birth).fromNow(true)})`
        : '',
    },
    {
      title: 'Gender',
      value: person?.gender,
    },
    {
      title: 'Ethnicity',
      value: person?.ethnicity,
    },
    {
      title: 'Dietary Preferences',
      value: person?.dietary_preferences,
    },
    {
      title: 'Has Children',
      value: person?.has_children ? 'Yes' : person.has_children === false ? 'No' : 'Unknown',
    },
    {
      title: 'Family Status',
      value: person?.family_status,
    },
    {
      title: 'Risk Tolerance',
      value: person?.risk_tolerance,
    },
    {
      title: 'Extraversion',
      value: person?.extraversion,
    },
    {
      title: 'Stripe Customer ID',
      value: person?.stripe_customer_id ? (
        <>
          <span>{person.stripe_customer_id}</span>
          <Button
            onClick={() => window.open(`${getStripeUrl()}/customers/${person.stripe_customer_id}`)}
            color={'ghost'}
            size={'xs'}
            variant={'link'}
          >
            <ArrowUpRightIcon color={'black'} className={'h-4 w-4'} />
          </Button>
        </>
      ) : null,
    },
    {
      title: 'Access Requirements',
      value: person?.access_requirements,
    },
    {
      title: 'Sexual Orientation',
      value: person?.sexual_orientation,
    },
  ];

  return <DetailTableSection title={'Personal Details'} details={details} />;
};
