export const InterviewFormNameToStars = {
  slope: 3,
  experience: 10,
  transactional: 3,
  vulnerability: 3,
  intention_focus: 3,
  conversation_focus: 3,
  extraversion: 5,
  energy: 10,
  work_life: 3,
  overall: 5,
};
