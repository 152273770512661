import { Person } from '@/__generated__/graphql';
import { DetailTableSection } from '@/components/sections/DetailTableSection';
import { toTitleCase } from '@/shared/utils/utils';

type Props = {
  person: Person;
};

export const ApplicationRatings = ({ person }: Props) => {
  const ratingFields = Object.keys(person).filter((key) => key.startsWith('rating'));

  const details = [
    ...ratingFields.map((key) => ({
      title: toTitleCase(key.replaceAll('_', ' ')).replace('Rating', 'Rating:'),
      value: person[key],
    })),
  ];

  return <DetailTableSection title={'Application Ratings'} details={details} />;
};
