import { QueueListIcon } from '@heroicons/react/24/outline';
import { useLocation } from 'react-router-dom';

import { Interview } from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import { BasicLayout } from '@/components/layouts/BasicLayout/BasicLayout';
import { ApplicationDetails } from '@/components/personDetailSections/detailsSections/ApplicationDetails';
import { PersonBasicDetails } from '@/components/personDetailSections/detailsSections/PersonBasicDetails';
import { InterviewDetails } from '@/pages/Interviews/InterviewsQueuePage/components/sections/InterviewDetails';
import { InterviewForm } from '@/pages/Interviews/InterviewsQueuePage/components/sections/InterviewForm';

type Props = {
  selectedInterview?: Interview;
  isMobile?: boolean;
  setQueueDropdownOpen: (isOpen: boolean) => void;
};

export const InterviewPageContent = ({
  selectedInterview,
  isMobile,
  setQueueDropdownOpen,
}: Props) => {
  const location = useLocation();
  const isFinishedInterviewsPage = location.pathname.includes('finished-interviews');
  return (
    <BasicLayout
      title={
        <span className={'mb-2 flex w-full'}>
          {selectedInterview
            ? `${selectedInterview.person.first_name} ${selectedInterview.person.last_name}`
            : isFinishedInterviewsPage
              ? 'Finished Interviews'
              : 'Interview Queue'}
          <span className={'flex-1'} />
          {isMobile && (
            <Button
              onClick={() => setQueueDropdownOpen(true)}
              size={'sm'}
              shape={'circle'}
              color={'ghost'}
              variant={'outline'}
              className={'p-1'}
            >
              <QueueListIcon />
            </Button>
          )}
        </span>
      }
    >
      {!selectedInterview && (
        <label className={'mt-14 block text-center text-2xl'}>No selected Interview</label>
      )}

      {selectedInterview && (
        <div className={'grid grid-cols-1 gap-2 lg:grid-cols-2'}>
          <div className={'flex flex-col gap-2'}>
            <PersonBasicDetails person={selectedInterview.person} />
            <InterviewDetails interview={selectedInterview} />
            <ApplicationDetails person={selectedInterview?.person} />
          </div>
          <div>
            <InterviewForm interview={selectedInterview} />
          </div>
        </div>
      )}
    </BasicLayout>
  );
};
