import { XMarkIcon } from '@heroicons/react/24/outline';
import { debounce, groupBy } from 'lodash-es';
import { ReactNode, useEffect, useRef, useState } from 'react';

import Button from '@/components/basic/buttons/Button';
import Input from '@/components/basic/inputs/Input';
import { QueueSection } from '@/components/queue/components/QueueSection';

export type StringOrNumberKeys<T> = {
  [K in keyof T]: T[K] extends string | number ? K : never;
}[keyof T];

type Props<T extends { id: string | number }> = {
  items: T[];
  groupByParameter: keyof T;
  sortParam: keyof T;
  param1: StringOrNumberKeys<T>;
  param2?: StringOrNumberKeys<T> | undefined;
  dateParam: StringOrNumberKeys<T>;
  onSelect?: (item: T) => void;
  selectedItemId?: string | number;
  searchEnabled?: boolean;
  filterBar?: ReactNode;
  sortDirection?: 'asc' | 'desc';
  dropDownEnabled?: boolean;
  openDropDown?: boolean;
  onDropDownClose?: () => void;
  categoryConfigurations?: Record<string, Record<string, any> | undefined>;
  hideMissingCategories?: boolean;
};

export const Queue = <T extends { id: string | number }>({
  items,
  groupByParameter,
  dateParam,
  param1,
  param2,
  onSelect,
  selectedItemId,
  searchEnabled,
  filterBar,
  sortParam,
  sortDirection,
  dropDownEnabled,
  openDropDown,
  onDropDownClose,
  categoryConfigurations,
  hideMissingCategories,
}: Props<T>) => {
  const [groupedItems, setGroupedItems] = useState({});
  const categories =
    Object.keys(
      hideMissingCategories && categoryConfigurations ? categoryConfigurations : groupedItems,
    ) || [];
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearch = useRef(
    debounce(async (criteria: string) => {
      setSearchTerm(criteria);
    }, 300),
  ).current;

  function groupItems() {
    let filteredItems = items;
    if (searchTerm) {
      filteredItems = items.filter((item) => {
        const itemString = String(item?.[param1]);
        const searchString = searchTerm.toLowerCase();
        return itemString.toLowerCase().includes(searchString);
      });
    }

    setGroupedItems(groupBy(filteredItems, groupByParameter));
  }

  useEffect(() => {
    groupItems();

    return () => {
      debouncedSearch.cancel();
    };
  }, [items, searchTerm]);

  return (
    <div
      className={`flex w-full flex-col overflow-auto bg-gray-100 ${dropDownEnabled ? `absolute z-20 transition-all ${openDropDown ? 'h-full' : 'h-0'}` : 'h-full max-w-[300px]'}`}
    >
      <div className={'flex w-full items-center justify-end gap-3 px-2'}>
        {filterBar}
        {dropDownEnabled && (
          <Button onClick={onDropDownClose} size={'xs'} shape={'circle'} color={'ghost'}>
            <XMarkIcon />
          </Button>
        )}
      </div>
      {searchEnabled && (
        <Input
          onChange={(event) => debouncedSearch(event.target.value)}
          className={'rounded-none'}
          placeholder={'Search'}
          inputSize={'md'}
        />
      )}

      {Object.keys(groupedItems).length === 0 && (
        <label className={'pt-4 text-center text-gray-600'}>No Items</label>
      )}

      <div className={'flex flex-1 flex-col overflow-auto'}>
        {categories.map((status) => (
          <QueueSection
            status={status}
            listItems={groupedItems[status] || []}
            key={status}
            color={categoryConfigurations?.[status]?.color || 'black'}
            param1={param1}
            param2={param2}
            dateParam={dateParam}
            onSelect={onSelect}
            sortParam={sortParam}
            selectedItemId={selectedItemId}
            sortDirection={sortDirection}
          />
        ))}
      </div>
    </div>
  );
};
