import { Table } from '@tanstack/react-table';
import React from 'react';

import Button from '@/components/basic/buttons/Button';
import Select from '@/components/basic/inputs/Select';

type PaginationFooterProps<T> = {
  table: Table<T>;
};

const PaginationFooter = <T,>({ table }: PaginationFooterProps<T>): React.ReactElement => {
  return (
    <div className="sticky bottom-0 flex flex-wrap items-center justify-between gap-1 border-t bg-white px-4 py-2">
      {/* Pagination Controls */}
      <div className="flex gap-2">
        <Button
          size={'xs'}
          color={'info'}
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          « First
        </Button>
        <Button
          size={'xs'}
          color={'info'}
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          ‹ Prev
        </Button>
        <Button
          size={'xs'}
          color={'info'}
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next ›
        </Button>
        <Button
          color={'info'}
          size={'xs'}
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          Last »
        </Button>
      </div>

      {/* Page Info */}
      <div className={'text-xs'}>
        Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
      </div>

      {/* Rows Per Page */}
      <div className="flex items-center gap-2 text-xs">
        <span>Show</span>
        <Select
          inputSize={'xs'}
          value={table.getState().pagination.pageSize}
          onChange={(e) => table.setPageSize(Number(e.target.value))}
        >
          {[10, 20, 30, 40, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Select>
        <span>Rows</span>
        <span className={'ml-5 whitespace-nowrap'}>
          <b>Total Rows:</b> {table.getRowCount()}
        </span>
      </div>
    </div>
  );
};

export default PaginationFooter;
