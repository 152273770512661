import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { useCreateUserLogMutation, useGetUserLogsQuery } from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import { useAuth } from '@/hooks/useAuth';

type Props = {
  type?: string;
  personId: string;
  className?: string;
};

const LoggingComponent = ({ type, personId, className }: Props) => {
  const logsContainerRef = useRef<HTMLDivElement>(null);
  const {
    error: fetchError,
    data: logsRes,
    refetch,
    loading: loadingFetch,
  } = useGetUserLogsQuery({
    variables: { personId, type, pagination: { limit: 300, offset: 0 } },
  });
  const [createLog, { loading: loadingCreate, error: createError }] = useCreateUserLogMutation({
    onCompleted: () => {
      refetch({ personId, type, pagination: { limit: 300, offset: 0 } });
    },
  });
  const { user } = useAuth();
  const [newLog, setNewLog] = useState('');
  const activityLogs = logsRes?.getUserLogs;

  const handleSubmitLog = async () => {
    if (newLog.trim() === '') return;

    await createLog({
      variables: {
        input: {
          content: newLog,
          user_id: user!.id,
          person_id: personId,
          type: type,
        },
      },
    });

    setNewLog('');
  };

  useEffect(() => {
    if (logsContainerRef.current) {
      logsContainerRef.current.scrollTo({
        top: logsContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [activityLogs, logsContainerRef]);

  return (
    <div
      className={twMerge(
        'mx-auto flex h-full min-h-[300px] flex-col rounded-lg border border-gray-300 bg-base-100 shadow-md',
        className,
      )}
    >
      <div ref={logsContainerRef} className="flex flex-1 flex-col space-y-4 overflow-y-auto p-4">
        <span className={'flex-1'} />
        {(activityLogs || []).map((log) => (
          <div className={'w-full'} key={log.id}>
            {!log.user_id ? (
              <div className="rounded-lg bg-gray-100 p-2 text-center text-sm text-gray-500">
                <span className="font-medium">System:</span> {log.content}
                <div className="mt-1 text-xs opacity-50">{log.created_at}</div>
              </div>
            ) : (
              <div className={`chat flex w-full flex-col items-start`}>
                <div className="chat-header w-full">
                  <span className="font-semibold">{log!.user!.name}</span>
                  <time className="ml-2 text-xs opacity-50">
                    {dayjs(log.created_at).format('MMM D, YYYY h:mm A z')}
                  </time>
                </div>
                <div className="chat-bubble text-sm">{log.content}</div>
              </div>
            )}
          </div>
        ))}

        {loadingFetch ? (
          <div className="flex w-full items-center justify-center">
            <div className="loading" />
          </div>
        ) : !activityLogs?.length ? (
          <p className={'w-full text-center italic text-gray-400'}>No Logs</p>
        ) : null}

        {(fetchError || createError) && (
          <p className={'text-center text-xs text-error'}>
            {fetchError?.message || createError?.message}
          </p>
        )}
      </div>
      <div className="flex items-center gap-2 rounded-b-lg bg-base-200 p-2">
        <input
          type="text"
          className="input input-sm input-bordered w-full"
          placeholder="Write a log..."
          value={newLog}
          onChange={(e) => setNewLog(e.target.value)}
        />
        <Button
          loading={loadingCreate}
          disabled={loadingCreate}
          size={'sm'}
          onClick={handleSubmitLog}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default LoggingComponent;
