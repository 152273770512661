import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { captureException } from '@sentry/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/basic/buttons/Button';

type Props = {
  error: any;
  resetErrorBoundary: any;
};

export const ErrorBoundaryPage = ({ error, resetErrorBoundary }: Props) => {
  const navigate = useNavigate();

  const handleNav = () => {
    resetErrorBoundary();
    navigate('/', {});
  };

  useEffect(() => {
    captureException(error);
  }, []);

  return (
    <div className={'flex h-full w-full flex-col items-center justify-center'}>
      <h1 className={'translate-y-[-50%] text-center text-xl text-gray-800 sm:text-3xl'}>
        Mission Control, we have a problem.
        <ExclamationTriangleIcon
          fontSize={'inherit'}
          className={'mx-auto w-28 text-warning lg:w-56'}
        />
        If the issue continues, please contact @Brennan via slack.
      </h1>

      <Button onClick={handleNav}>To Safety</Button>
    </div>
  );
};
