import { CalendarIcon, ChevronDownIcon, ClockIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { useState } from 'react';

import Button from '@/components/basic/buttons/Button';
import { StringOrNumberKeys } from '@/components/queue/Queue';

interface Props<T extends { id: string | number }> {
  listItems: T[];
  status: string;
  color?: string;
  onSelect?: (item: T) => void;
  selectedItemId?: string | number;
  sortParam: keyof T;
  sortDirection?: 'asc' | 'desc';
  param1: StringOrNumberKeys<T>;
  param2?: StringOrNumberKeys<T>;
  dateParam: StringOrNumberKeys<T>;
}

export const QueueSection = <T extends { id: string | number }>({
  listItems,
  status,
  color,
  selectedItemId,
  onSelect,
  param1,
  param2,
  sortParam,
  dateParam,
  sortDirection = 'asc',
}: Props<T>) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div key={status} className={'flex flex-col'}>
      <div className={'flex items-center gap-1 border border-gray-500 bg-gray-200 p-2'}>
        <Button
          shape={'circle'}
          size={'xs'}
          color={'ghost'}
          onClick={() => setCollapsed(!collapsed)}
        >
          <ChevronDownIcon className={`w-4 ${collapsed ? '-rotate-90 transform' : ''}`} />
        </Button>
        <h6 className={`font-semibold tracking-wide`}>{status}</h6>
        <div style={{ background: `${color}` }} className={'h-2 w-4 rounded-full'} />
      </div>
      {!collapsed && (
        <div className={'flex flex-col overflow-auto'}>
          {listItems
            .sort((a, b) => {
              let comparison: number;

              if (sortParam === dateParam) {
                comparison =
                  new Date(String(b[sortParam])).getTime() -
                  new Date(String(a[sortParam])).getTime();
              } else {
                if (b[sortParam] > a[sortParam]) {
                  comparison = 1;
                } else if (b[sortParam] < a[sortParam]) {
                  comparison = -1;
                } else {
                  comparison = 0;
                }
              }

              return sortDirection === 'desc' ? comparison : -comparison;
            })
            .map((item) => {
              if (!item) return null;

              return (
                <div
                  key={item.id}
                  className={`flex w-full cursor-pointer gap-4 border p-3 ${String(selectedItemId) === String(item.id) ? 'bg-primary-100' : 'bg-white hover:bg-gray-100 active:bg-gray-200'}`}
                  onClick={onSelect ? () => onSelect(item) : undefined}
                >
                  <span
                    className={'h-full w-[6px] rounded-full'}
                    style={{ background: `${color}` }}
                  />
                  <div className={'flex flex-col text-sm'}>
                    <label className={'cursor-pointer font-semibold'}>{String(item[param1])}</label>
                    {dateParam && (
                      <>
                        <label className={'flex cursor-pointer gap-1 text-xs font-medium'}>
                          <CalendarIcon className={'w-3'} />{' '}
                          {dayjs(String(item[dateParam])).format('dddd, MMMM Do YYYY')}
                        </label>
                        <label className={'flex cursor-pointer gap-1 text-xs font-medium'}>
                          <ClockIcon className={'w-3'} />{' '}
                          {dayjs(String(item[dateParam])).format('h:mm a z')}
                        </label>
                      </>
                    )}
                    {param2 && (
                      <label className={'cursor-pointer font-light'}>
                        {String(item?.[param2])}
                      </label>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};
