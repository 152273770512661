import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import {
  FilterConditionOperatorEnum,
  FilterInput,
  GetExperiencesQuery,
  useGetExperiencesQuery,
} from '@/__generated__/graphql';
import BaseTable from '@/components/BaseTable/BaseTable';
import { FilterPanel } from '@/components/FilterPanel/FilterPanel';
import { IFilter } from '@/components/FilterPanel/FilterPanelContent';
import { BasicLayout } from '@/components/layouts/BasicLayout/BasicLayout';
import { CityEnum } from '@/shared/enums';
import { removeInvalidFilters } from '@/shared/utils/utils';

export const ExperiencesPage = () => {
  const [pagination, setPagination] = useState({ pageSize: 30, pageIndex: 0 });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filters, setFilters] = useState<FilterInput[]>([]);
  const {
    data: experiencesRes,
    loading,
    refetch: experiencesRefetch,
  } = useGetExperiencesQuery({
    variables: {
      sort: sorting.length
        ? {
            id: sorting[0]?.id,
            order: sorting[0]?.desc ? 'DESC' : 'ASC',
          }
        : undefined,
      pagination: {
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      },
      filters: removeInvalidFilters(filters),
    },
  });
  const experiences = experiencesRes?.getExperiences.experiences || [];
  const totalRecords = experiencesRes?.getExperiences.count;

  const columns: ColumnDef<GetExperiencesQuery['getExperiences']['experiences'][0]>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
      enableSorting: false,
    },
    {
      accessorKey: 'status',
      header: 'Status',
    },
    {
      accessorKey: 'title',
      header: 'Title',
      cell: ({ cell }) => (
        <div
          className={'min-w-full max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap'}
          title={cell.getValue() as any}
        >
          {cell.getValue() as any}
        </div>
      ),
    },
    {
      accessorKey: 'experience_type',
      header: 'Type',
    },
    {
      accessorKey: 'date',
      header: 'Date',
      accessorFn: (record) =>
        record.date ? dayjs(record.date).format('MMM D, YYYY h:mm A z') : '',
    },
    {
      accessorKey: 'city',
      enableSorting: true,
      header: 'City',
    },
    {
      accessorFn: (record) =>
        record?.price_per_seat ? `$${(record?.price_per_seat / 100).toFixed(2)}` : '',
      enableSorting: false,
      header: 'Price/Seat',
    },
    {
      accessorFn: (record) => record?.max_participants ?? '',
      enableSorting: false,
      header: 'Total Tickets',
    },
    {
      accessorKey: 'tickets_left',
      enableSorting: false,
      header: 'Available Tickets',
    },
    {
      accessorKey: 'max_guests_per_member',
      enableSorting: false,
      header: 'Guest/Member',
    },
    {
      accessorFn: (record) => record.description,
      cell: ({ cell }) => (
        <div className={'max-w-0'}>
          {<InformationCircleIcon title={cell.getValue() as any} className={'w-4'} />}
        </div>
      ),
      enableSorting: false,
      header: 'Description',
    },
  ];

  const getFilters = (): IFilter[] => {
    return [
      {
        field: 'city',
        type: 'string',
        operations: [FilterConditionOperatorEnum.Equals],
        values: Object.values(CityEnum),
      },
      {
        field: 'date',
        type: 'date',
        operations: [FilterConditionOperatorEnum.GreaterThan, FilterConditionOperatorEnum.LessThan],
      },
      {
        field: 'status',
        type: 'string',
        operations: [FilterConditionOperatorEnum.Contains],
      },
    ];
  };

  useEffect(() => {
    experiencesRefetch({
      sort: sorting.length
        ? {
            id: sorting[0]?.id,
            order: sorting[0]?.desc ? 'DESC' : 'ASC',
          }
        : undefined,
      pagination: {
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      },
      filters: removeInvalidFilters(filters),
    });
  }, [filters, sorting, pagination]);

  return (
    <BasicLayout title={'Upcoming Experiences'} className={'flex flex-col'}>
      <div className={'flex h-10 w-full items-center justify-end border-y border-neutral px-4'}>
        <FilterPanel filterKeyValues={getFilters()} filters={filters} setFilters={setFilters} />
      </div>

      <BaseTable
        data={experiences}
        columns={columns}
        loading={loading}
        paginationEnabled={true}
        manualPagination={true}
        paginationDetails={pagination}
        totalRecords={totalRecords}
        onPaginationChange={setPagination}
        sorting={sorting}
        onSortingChange={setSorting}
      />
    </BasicLayout>
  );
};
