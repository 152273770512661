import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { FilterConditionOperatorEnum, FilterInput } from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';

interface Props {
  filters: FilterInput[];
  setFilters: (arg: FilterInput[]) => void;
}

export const AccountManagementQuickFilters = ({ filters, setFilters }: Props) => {
  const [activeFilter, setActiveFilter] = useState('');

  const quickFilters: { label: string; key: string; filters: FilterInput[] }[] = [
    {
      label: 'Under 4 Events',
      key: 'under4Events',
      filters: [
        { operator: FilterConditionOperatorEnum.LessThan, value: '4', field: 'total_events' },
      ],
    },
    {
      label: 'New Users',
      key: 'newUsers',
      filters: [
        {
          operator: FilterConditionOperatorEnum.GreaterThan,
          value: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
          field: 'membership_started_at',
        },
      ],
    },
  ];

  useEffect(() => {
    const foundKey = quickFilters.find(
      (filter) => JSON.stringify(filter.filters) === JSON.stringify(filters),
    )?.key;

    setActiveFilter(foundKey || '');
  }, [filters]);

  return (
    <div className={'flex flex-wrap items-center gap-1'}>
      <p className={'mr-2 text-sm'}>Quick Filters:</p>
      {quickFilters.map(({ label, key, filters }) => {
        return (
          <Button
            onClick={() => (activeFilter === key ? null : setFilters(filters))}
            key={key}
            color={activeFilter === key ? 'success' : 'neutral'}
            variant={activeFilter === key ? undefined : 'outline'}
            size={'xs'}
          >
            {label}
          </Button>
        );
      })}
    </div>
  );
};
